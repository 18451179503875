import { RefObject, useLayoutEffect, useState } from 'react';

/**
 * Color in RGB color space, values from [0, 1]
 */
export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

// standard debug color: Magenta
const DEBUG = { r: 1, g: 0, b: 1 };

/**
 * Returns a color object from the current computed `color` style.
 * Right now it's guaranteed to work for `rgb` and common colors. Have
 * not tried it in alternate color spaces yet.
 * @param ref ref of the element to test
 * @returns color in RGB space
 */
export function useColor<T extends Element>(ref: RefObject<T>): RGBColor {
  const [color, setColor] = useState(DEBUG);

  useLayoutEffect(() => {
    if (ref.current) {
      const c = getComputedStyle(ref.current).color;
      if (c.startsWith('rgb(')) {
        const [r, g, b] = c.replace('rgb(', '').replace(')', '').split(',');
        const newColor = {
          r: Number(r) / 255,
          g: Number(g) / 255,
          b: Number(b) / 255,
        };
        if (
          color.r !== newColor.r ||
          color.g !== newColor.g ||
          color.b !== newColor.b
        ) {
          setColor(newColor);
        }
      }
    }
  }, [color.b, color.g, color.r, ref]);

  return color;
}

export function formatColor(color: RGBColor, alpha?: number): string {
  if (alpha === undefined) {
    return `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`;
  }
  return `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, ${alpha})`;
}
