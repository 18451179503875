import createCache from '@emotion/cache';

function getInsertionPoint() {
  if (typeof document === 'undefined') {
    return {};
  }

  const head = document.querySelector('head');
  if (!head) {
    return {};
  }
  let insertionPoint: HTMLMetaElement | undefined = undefined;
  insertionPoint =
    head.querySelector<HTMLMetaElement>('meta[name="gds"]') ?? undefined;
  if (!insertionPoint) {
    insertionPoint = document.createElement('meta');
    insertionPoint.setAttribute('name', 'gds');
    insertionPoint.setAttribute('content', '');
    head.prepend(insertionPoint);
  }

  return { insertionPoint };
}

export const emotionCache = createCache({
  key: 'gds',
  ...getInsertionPoint(),
});
