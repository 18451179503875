import { CreateContextOptions, createContext } from '@gds-web-ui/utils-context';
import type { Theme, ThemeProviderProps } from './types';

export type CreateThemeContextOptions<TTheme extends Theme> = Omit<
  CreateContextOptions<TTheme>,
  'strict' | 'defaultValue'
>;

export const createThemeContext = <TTheme extends Theme>(
  defaultTheme: TTheme,
  options: CreateThemeContextOptions<TTheme> = {}
) => {
  const [ContextProvider, useTheme, Context] = createContext({
    ...options,
    defaultValue: defaultTheme,
    strict: false,
  });

  const ThemeProvider = ({
    theme: themeProp,
    children,
  }: ThemeProviderProps<TTheme>) => {
    const themeFromContext = useTheme() || defaultTheme;

    const themeValue =
      typeof themeProp === 'function'
        ? themeProp(themeFromContext) || themeFromContext
        : themeProp || themeFromContext;

    return <ContextProvider value={themeValue}>{children}</ContextProvider>;
  };

  return [ThemeProvider, useTheme, Context] as const;
};
