import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

export type PortalContainerOptions = {
  containerRef?: RefObject<Element | DocumentFragment> | undefined;

  prefix?: string | undefined;

  inPlace?: boolean | undefined;
};

/**
 * Create and return a Portal container
 *
 *
 * ```
 * const { Portal } = usePortalContainer();
 * // ...
 *
 * return (
 *   <Portal>
 *     { portal content goes here}
 *   </Portal>
 * );
 * ```
 *
 * @param prefix Prefix of the portal container class name
 * @returns Returns an object containing the `container` div element and the `Portal` itself.
 */
export const usePortalContainer = ({
  prefix = 'gds',
  inPlace = false,
  containerRef = undefined,
}: PortalContainerOptions) => {
  const [container] = useState<HTMLDivElement>(() => {
    const container = document.createElement('div');
    container.className = `${prefix}-portal`;
    return container;
  });

  useEffect(() => {
    const parentNode = containerRef?.current ?? document.body;
    parentNode.appendChild(container);

    return () => {
      parentNode.removeChild(container);
    };
  }, [container, containerRef]);

  const Portal = useCallback(
    ({ children }: PropsWithChildren) => {
      if (inPlace) {
        return <>{children}</>;
      }
      return ReactDOM.createPortal(children, container);
    },
    [container, inPlace]
  );

  return { container, Portal };
};

export const Portal = ({
  children,
  ...otherOptions
}: PropsWithChildren<PortalContainerOptions>) => {
  const { Portal: InnerPortal } = usePortalContainer(otherOptions);
  return <InnerPortal>{children}</InnerPortal>;
};
