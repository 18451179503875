export type NavigationIconType = 'back' | 'close' | 'dropdown' | 'next';

export const NavigationIcon = ({ type }: { type: NavigationIconType }) => {
  switch (type) {
    case 'back':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="back"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 12.0019C3.00067 12.3849 3.14711 12.7677 3.43934 13.0599L9.6617 19.2823C9.95386 19.5744 10.4275 19.5744 10.7197 19.2823C11.0119 18.9901 11.0119 18.5164 10.7197 18.2243L5.24543 12.75H20.25C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25H5.24397L10.7168 5.77713C11.009 5.48497 11.009 5.01128 10.7168 4.71912C10.4247 4.42696 9.95099 4.42696 9.65883 4.71912L3.43934 10.9386C3.14643 11.2315 2.99999 11.6154 3 11.9993C3 11.9995 3 11.9998 3 12C3 12.0006 3 12.0013 3 12.0019Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'close':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          aria-label="close"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0H24V24H0z" />
            <g stroke="currentColor" strokeLinecap="square" strokeWidth="2">
              <path d="M14 0L0 14M14 14L0 0" transform="translate(5 5)" />
            </g>
          </g>
        </svg>
      );
    case 'dropdown':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_8917_2432)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8443 5.59874C12.6393 5.39372 12.3068 5.39372 12.1018 5.59874L8.75928 8.9413C8.55514 9.14543 8.42408 9.27592 8.31668 9.36709C8.21397 9.45429 8.16792 9.47429 8.14592 9.48144C8.05052 9.51244 7.94775 9.51244 7.85235 9.48144C7.83035 9.47429 7.78431 9.45429 7.68159 9.36709C7.57419 9.27592 7.44313 9.14543 7.239 8.9413L3.89647 5.59874C3.69144 5.39372 3.35903 5.39372 3.154 5.59874C2.94898 5.80377 2.94898 6.13618 3.154 6.34121L6.51071 9.69794C6.69661 9.88385 6.85802 10.0453 7.00206 10.1676C7.15444 10.2969 7.32156 10.413 7.52788 10.48C7.83417 10.5796 8.1641 10.5796 8.47039 10.48C8.67672 10.413 8.84384 10.2969 8.99621 10.1676C9.14026 10.0453 9.30167 9.88384 9.48757 9.69793L12.8443 6.34121C13.0493 6.13618 13.0493 5.80377 12.8443 5.59874Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_8917_2432">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'next':
      // FIXME: asset not available yet
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.503 8.5H2.5C2.22386 8.5 2 8.27614 2 8C2 7.72386 2.22386 7.5 2.5 7.5H12.504L8.85544 3.85142C8.66067 3.65665 8.66067 3.34085 8.85544 3.14608C9.05022 2.95131 9.36601 2.95131 9.56078 3.14608L13.7071 7.2924C14.0976 7.68293 14.0976 8.31609 13.7071 8.70662L9.55887 12.8549C9.36409 13.0496 9.0483 13.0496 8.85353 12.8549C8.65875 12.6601 8.65875 12.3443 8.85353 12.1495L12.503 8.5Z"
            fill="#FF00FF"
          />
        </svg>
      );
  }
};
