import { useMemo } from 'react';

type RefArg<T> = ((value: T) => void) | { current: T } | undefined | null;

const setRef = <T>(ref: RefArg<T>, value: T) => {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  ref.current = value;
};

export function forkRef<T>(...refs: RefArg<T>[]) {
  const filteredRefs = refs.filter(Boolean);
  switch (filteredRefs.length) {
    case 0:
      return undefined;
    case 1:
      return (value: T) => {
        setRef(filteredRefs[0], value);
      };
    default:
      return (value: T) => {
        refs.forEach((ref) => setRef(ref, value));
      };
  }
}

/**
 * Combines multiple React Refs into one
 * @returns Returns the combined Ref
 */
export function useForkRef<T>(...refs: RefArg<T>[]) {
  // lint being disabled because we want to memoize the return value
  // based on the contents of the `refs` array, not the array itself.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => forkRef(...refs), refs);
}
