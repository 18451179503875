export * from "./deferred"

let counter = 0;
let immediateInitialised = false;
const immediates: Record<string, () => void> = {};

/**
 * Returns a promise that resolves after a specified time
 */
export function sleep(ms: number): Promise<void> {
  return new Promise((res) => setTimeout(res, ms));
}

/**
 * Returns a promise that resolves by `requestAnimationFrame`.
 */
export function nextFrame(): Promise<void> {
  return new Promise((res) => requestAnimationFrame(() => res()));
}

/**
 * Returns a promise that resolves by `queueMicrotask`.
 */
export function microtask(): Promise<void> {
  return new Promise((res) => queueMicrotask(res));
}

export function idle(): Promise<void> {
  if (typeof requestIdleCallback === 'function') {
    return new Promise((res) => requestIdleCallback(() => res()));
  }
  return immediate();
}

/**
 * Returns a promise that resolves by `setImmediate`.
 */
export function immediate(): Promise<void> {
  const id = `imm@${counter++}`;
  const promise = new Promise<void>((res) => (immediates[id] = res));

  if (!immediateInitialised) {
    window.addEventListener('message', (event: MessageEvent<string>) => {
      if (immediates[event.data]) {
        const f = immediates[event.data];
        delete immediates[event.data];
        if (f) {
          f();
        }
      }
    });
    immediateInitialised = true;
  }

  window.postMessage(id);
  return promise;
}
