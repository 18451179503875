import { createLibraryComponent } from '@gds-web-ui/core';
import {
  IconButton,
  IconButtonContextProvider,
} from '@gds-web-ui/duxton-icon-button';
import { useSafeArea } from '@gds-web-ui/duxton-safe-area-context';
import { defineComponentStructure } from '@gds-web-ui/duxton-theme';
import { AppBarLargeTitle } from './large-title';
import { NavigationIcon } from './navigation-icon';

export interface AppBarProps {
  /**
   * Defines the icon to be displayed on the leading side of the App Bar.
   *
   * Only supports the 4 specified icons, namely:
   *
   * 1. `back` - Arrow to the left
   * 1. `close` - Cross
   * 1. `dropdown` - Arrow to the bottom (NOT YET IMPLEMENTED)
   * 1. `next` - Arrow to the right (NOT YET IMPLEMENTED)
   */
  navigationIcon?: 'back' | 'close' | 'dropdown' | 'next' | undefined;
  /**
   * The title of the app bar. This can be either:
   * 1. A string, which we will assume to be a large title
   * 1. One of `AppBar.Content.*` element, for each of the
   * specified content options (note that Search is not support yet.)
   * 1. Any arbitrary React Element (You're on your own at this point.)
   */
  content?: string | JSX.Element | undefined;
  /**
   * If `children` is supplied, it'll be rendered in the trailing side
   * of the App Bar (usually for action buttons).
   *
   * We will, by default, apply a 8px gap between elements.
   */
  children?: JSX.Element | undefined;
  /**
   * Event listener when the leading navigation IconButton (usually back)
   * is pressed.
   */
  onNavigationPress?: () => void;
  transparent?: boolean;
}

export const appBarStructure = defineComponentStructure('mobile.appbar')
  .parts('wrapper', 'container', 'content', 'action')
  .variants('default', 'transparent')
  .sizes('default');

export const [defineAppBarStyles, useAppBarStyles] = appBarStructure.utils();

export const defaultAppBarStyles = defineAppBarStyles(
  ({ fontFamily, backgroundColors, spacing }) => ({
    base: {
      wrapper: {
        backgroundColor: backgroundColors.default,
        fontFamily: fontFamily.regular,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        userSelect: 'none',
        boxSizing: 'border-box',
      },
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        height: '64px',
        paddingLeft: spacing.medium,
        paddingRight: spacing.medium,
        gap: spacing.small,
        display: 'flex',
      },
      content: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
      action: {
        display: 'flex',
        flexDirection: 'row',
        gap: spacing.small,
      },
    },
    variants: {
      transparent: {
        wrapper: {
          backgroundColor: 'transparent',
        },
      },
    },
  })
);

/**
 * Enables navigation between app screens while displaying information and actions related to the current screen.
 *
 * [Design Spec](https://grab.design/experiences/consumers/components/app-bar)
 */
export const AppBar = createLibraryComponent<AppBarProps, HTMLDivElement>(
  ({
    navigationIcon,
    content,
    children,
    onNavigationPress,
    UNSAFE_className,
    forwardedRef,
    transparent,
  }) => {
    const { getStyles } = useAppBarStyles(
      { variant: transparent ? 'transparent' : 'default', size: 'default' },
      defaultAppBarStyles
    );
    const { top } = useSafeArea('padding');
    return (
      <IconButtonContextProvider
        value={{
          size: 'large',
          variant: transparent ? 'translucent' : 'opaque',
        }}
      >
        <div
          css={[getStyles('wrapper'), top]}
          ref={forwardedRef}
          className={UNSAFE_className}
        >
          <div css={getStyles('container')}>
            {navigationIcon && (
              <IconButton
                size="large"
                onPress={onNavigationPress}
                icon={<NavigationIcon type={navigationIcon} />}
                variant="default"
              />
            )}
            <div css={getStyles('content')}>
              {typeof content === 'string' ? (
                <AppBarLargeTitle>{content}</AppBarLargeTitle>
              ) : (
                content
              )}
            </div>
            {children && <div css={getStyles('action')}>{children}</div>}
          </div>
        </div>
      </IconButtonContextProvider>
    );
  }
);
