import { createLibraryComponent } from '@gds-web-ui/core';
import { SizeOf, defineComponentStructure } from '@gds-web-ui/duxton-theme';
import { TextInternal, TextInternalProps } from './text-internal';

export const textStructure = defineComponentStructure('mobile.typography.text')
  .parts('root', 'bolder')
  .variants('default')
  .sizes('callout01', 'callout02', 'callout03', 'metadata01', 'metadata02');

export const [defineTextStyles, useTextStyles] = textStructure.utils();

export const defaultTextStyles = defineTextStyles(
  ({ fontFamily, typography }) => ({
    base: {
      root: {
        fontFamily: fontFamily.regular,
        userSelect: 'none',
        cursor: 'default',
      },
    },
    sizes: {
      callout01: {
        root: typography.callout01Bolder,
      },
      callout02: {
        root: typography.callout02Bolder,
      },
      callout03: {
        root: typography.callout03Bolder,
      },
      metadata01: {
        root: typography.metadata01Regular,
      },
      metadata02: {
        root: [
          typography.metadata02Regular,
          {
            'strong, b': typography.metadata02Bolder,
          },
        ],
        bolder: typography.metadata02Bolder,
      },
    },
  })
);

export interface TextPropInternal extends Omit<TextInternalProps, 'component'> {
  size: SizeOf<typeof textStructure>;
  bolder?: boolean | undefined;
}

export type TextProp = Omit<TextPropInternal, 'size' | 'bolder'>;
export type Metadata02Props = Omit<TextPropInternal, 'size'>;

export const Text = createLibraryComponent<TextPropInternal, HTMLSpanElement>(
  function Text({
    size,
    children,
    forwardedRef,
    UNSAFE_className,
    bolder,
    ...props
  }) {
    const { getStyles } = useTextStyles(
      { variant: 'default', size },
      defaultTextStyles
    );

    return (
      <TextInternal
        {...props}
        component="span"
        css={[props.style, getStyles('root'), bolder && getStyles('bolder')]}
        ref={forwardedRef}
        className={UNSAFE_className}
      >
        {children}
      </TextInternal>
    );
  }
);

export const Callout01 = createLibraryComponent<TextProp, HTMLSpanElement>(
  function Callout01({ forwardedRef, ...props }) {
    return <Text {...props} ref={forwardedRef} size="callout01" />;
  }
);

export const Callout02 = createLibraryComponent<TextProp, HTMLSpanElement>(
  function Callout02({ forwardedRef, ...props }) {
    return <Text {...props} ref={forwardedRef} size="callout02" />;
  }
);

export const Callout03 = createLibraryComponent<TextProp, HTMLSpanElement>(
  function Callout03({ forwardedRef, ...props }) {
    return <Text {...props} ref={forwardedRef} size="callout03" />;
  }
);

export const Metadata01 = createLibraryComponent<TextProp, HTMLSpanElement>(
  function Metadata01({ forwardedRef, ...props }) {
    return <Text {...props} ref={forwardedRef} size="metadata01" />;
  }
);

export const Metadata02 = createLibraryComponent<
  Metadata02Props,
  HTMLSpanElement
>(function Metadata02({ forwardedRef, ...props }) {
  return <Text {...props} ref={forwardedRef} size="metadata02" />;
});
