import { Swatch } from "node-vibrant/lib/color";

export const getColorsFromSwatches = (swatches: any) => {
  const colors = [];

  for (let swatch in swatches) {
    if (swatches.hasOwnProperty(swatch) && swatches[swatch]) {
      colors.push(swatches[swatch].getHex());
    }
  }

  return colors;
};
