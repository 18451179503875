import { css } from '@emotion/react';
import { createLibraryComponent } from '@gds-web-ui/core';
import {
  DuxtonTheme,
  VariantOf,
  defineComponentStructure,
} from '@gds-web-ui/duxton-theme';
import { createContext } from '@gds-web-ui/utils-context';
import deepmerge from 'deepmerge';

export const iconButtonStructure = defineComponentStructure('mobile.iconbutton')
  .parts('container', 'text', 'label')
  .variants('default', 'opaque', 'translucent')
  .sizes('x-large', 'large', 'medium', 'small');

export type Size = 'x-large' | 'large' | 'medium' | 'small';

export interface IconButtonProps {
  /** Determine the size of the IconButton */
  size: Size;
  /**
   * Whether the button contains a background when in the idle (i.e. non-pressed)
   * state.
   * @deprecated use `variant=opaque` instead.
   */
  hasBackground?: boolean;
  /**
   * The label to apply to the button. This will alter left and
   * right paddings of the IconButton as per design spec.
   *
   * This prop is ignored when `size` is `small`.
   */
  label?: string | undefined;
  /**
   * Either a `src` attribute to `img` tag, or a JSX element.
   */
  icon: string | JSX.Element;
  color?: string;
  onPress?: (() => void) | undefined;

  /**
   * Define the variant of the IconButton. Mainly controls if the background is
   *  - absent
   *  - translucent
   *  - opaque
   */
  variant?: VariantOf<typeof iconButtonStructure> | undefined;
}

function scale(size: Size): [number, number] {
  switch (size) {
    case 'large':
      return [40, 24];
    case 'medium':
      return [32, 16];
    case 'small':
      return [16, 12];
    case 'x-large':
      return [48, 32];
  }
}

function getFontSize(size: Size, theme: DuxtonTheme) {
  switch (size) {
    case 'x-large':
      return theme.typography.heading04Bolder;
    case 'large':
      return theme.typography.body01Regular;
    case 'medium':
      return theme.typography.callout03Bolder;
    default:
      return theme.typography.callout03Bolder;
  }
}

function containerStyle([height, iconHeight]: [number, number]) {
  return css`
    height: ${height}px;
    padding: ${(height - iconHeight) / 2}px;

    div:first-of-type {
      height: ${iconHeight}px;
      width: ${iconHeight}px;

      * {
        width: 100%;
        height: 100%;
      }
    }

    img {
      vertical-align: top;
    }
  `;
}

export const [defineIconButtonStyles, useIconButtonStyles] =
  iconButtonStructure.utils();

export const defaultIconButtonStyles = defineIconButtonStyles((theme) => {
  const { fontFamily, spacing, contentColors, backgroundColors } = theme;
  return {
    base: {
      container: {
        border: '0 none',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        userSelect: 'none',
        borderRadius: '100vh',
        color: contentColors.default,
        alignItems: 'center',
        gap: spacing.tiny,
        ':active': {
          backgroundColor: backgroundColors.alt,
        },
      },
      text: {
        fontFamily: fontFamily.regular,
        flex: 1,
        whiteSpace: 'nowrap',
      },
      label: {
        paddingLeft: `calc(${spacing.medium} - ${spacing.tiny})`,
        paddingRight: spacing.medium,
      },
    },
    sizes: {
      small: {
        container: containerStyle(scale('small')),
        text: getFontSize('small', theme),
      },
      medium: {
        container: containerStyle(scale('medium')),
        text: getFontSize('medium', theme),
      },
      large: {
        container: containerStyle(scale('large')),
        text: getFontSize('large', theme),
      },
      'x-large': {
        container: containerStyle(scale('x-large')),
        text: getFontSize('x-large', theme),
      },
    },
    variants: {
      opaque: {
        container: {
          backgroundColor: backgroundColors.default,
        },
      },
      default: {
        container: {
          backgroundColor: 'transparent',
        },
      },
      translucent: {
        container: {
          backgroundColor: 'rgba(255, 255, 255, 24%)',
        },
      },
    },
  };
});

export type IconButtonContext = Pick<IconButtonProps, 'size' | 'variant'>;

const [IconButtonContextProvider, useComponentGroupContext] = createContext<
  Partial<IconButtonContext>
>({ strict: false });

export { IconButtonContextProvider };

function filterUndefined<T>(p: Partial<T>): Partial<T> {
  return Object.fromEntries(
    Object.entries(p).filter(([, value]) => value !== undefined)
  ) as Partial<T>;
}

function mergeContext({
  fromProps,
  fromContext,
  defaultValue,
}: {
  fromProps: Partial<IconButtonContext> & {
    hasBackground?: boolean | undefined;
  };
  fromContext: Partial<IconButtonContext> | undefined;
  defaultValue: IconButtonContext;
}): IconButtonContext {
  let result = defaultValue;

  if (fromContext) {
    result = deepmerge(result, filterUndefined(fromContext));
  }

  const { hasBackground, ...fromPropsFiltered } = fromProps;

  result = deepmerge(result, filterUndefined(fromPropsFiltered));

  if (hasBackground !== undefined) {
    if (hasBackground) {
      result.variant = 'opaque';
    } else {
      result.variant = 'default';
    }
  }

  return result;
}

/**
 * Icon Button is used to perform actions or make choices with a single tap.
 *
 * [Design Specs](https://grab.design/experiences/consumers/components/icon-button)
 */
export const IconButton = createLibraryComponent<
  IconButtonProps,
  HTMLButtonElement
>(
  ({
    size: incomingSize,
    label,
    icon,
    hasBackground,
    onPress,
    UNSAFE_className,
    forwardedRef,
    variant: incomingVariant,
    color,
  }) => {
    const { size, variant } = mergeContext({
      fromContext: useComponentGroupContext(),
      fromProps: {
        size: incomingSize,
        variant: incomingVariant,
        hasBackground,
      },
      defaultValue: { size: 'large', variant: 'opaque' },
    });

    const hasLabel = label && size !== 'small';
    const { getStyles } = useIconButtonStyles(
      { size, variant: variant ?? 'default' },
      defaultIconButtonStyles
    );
    return (
      <button
        css={[
          getStyles('container'),
          hasLabel && getStyles('label'),
          color ? { color } : undefined,
        ]}
        onClick={onPress}
        className={UNSAFE_className}
        ref={forwardedRef}
      >
        <div>
          {typeof icon === 'string' ? <img src={icon} alt={label} /> : icon}
        </div>
        {hasLabel && <span css={getStyles('text')}>{label}</span>}
      </button>
    );
  }
);
