import { defineComponentStructure } from '@gds-web-ui/duxton-theme';
import { Callout01, Metadata01 } from '@gds-web-ui/duxton-typography';

export interface AppBarTitleAndSubheadingProps {
  title: string;
  subheading: string;
}

export const appBarTitleAndSubheadingStructure = defineComponentStructure(
  'mobile.appbar.titleandsubheading'
)
  .parts('title', 'subheading')
  .variants('default')
  .sizes('default');

export const [
  defineAppBarTitleAndSubheadingStyles,
  useAppBarTitleAndSubheadingStyles,
] = appBarTitleAndSubheadingStructure.utils();

export const defaultAppBarTitleAndSubheadingStyles =
  defineAppBarTitleAndSubheadingStyles(({ contentColors }) => ({
    base: {
      title: {
        color: contentColors.default,
      },
      subheading: {
        color: contentColors.dim,
      },
    },
  }));

export const AppBarTitleAndSubheading = ({
  title,
  subheading,
}: AppBarTitleAndSubheadingProps) => {
  const { getStyles } = useAppBarTitleAndSubheadingStyles(
    { variant: 'default', size: 'default' },
    defaultAppBarTitleAndSubheadingStyles
  );
  return (
    <>
      <Callout01 style={getStyles('title')} role="heading" aria-level={1}>
        {title}
      </Callout01>
      <Metadata01 style={getStyles('subheading')}>{subheading}</Metadata01>
    </>
  );
};
