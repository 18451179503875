import { CacheProvider } from '@emotion/react';
import { emotionCache } from './emotionCache';

export const EmotionCacheProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <CacheProvider value={emotionCache}>{children}</CacheProvider>;
}
