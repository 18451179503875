import { nextFrame } from '@gds-web-ui/utils-async';

async function measureFrame(
  measure: (element: HTMLElement) => number,
  element: HTMLElement,
  framesUntilStable: number,
  stableFrames: number,
  previousWidth: number
): Promise<number> {
  await nextFrame();
  const width = measure(element);

  if (width === previousWidth) {
    if (!stableFrames) {
      return width;
    }
    return measureFrame(
      measure,
      element,
      framesUntilStable,
      stableFrames - 1,
      previousWidth
    );
  }
  return measureFrame(
    measure,
    element,
    framesUntilStable,
    framesUntilStable,
    width
  );
}

export function measure(
  measurement: (element: HTMLElement) => number,
  element: HTMLElement,
  framesUntilStable = 30
): Promise<number> {
  return measureFrame(
    measurement,
    element,
    framesUntilStable,
    framesUntilStable,
    -1
  );
}

measure.clientHeight = measure.bind(undefined, (e) => e.clientHeight);
measure.clientWidth = measure.bind(undefined, (e) => e.clientWidth);
measure.clientTop = measure.bind(undefined, (e) => e.clientTop);
measure.clientLeft = measure.bind(undefined, (e) => e.clientLeft);
measure.offsetHeight = measure.bind(undefined, (e) => e.offsetHeight);
measure.offsetWidth = measure.bind(undefined, (e) => e.offsetWidth);
measure.offsetTop = measure.bind(undefined, (e) => e.offsetTop);
measure.offsetLeft = measure.bind(undefined, (e) => e.offsetLeft);
