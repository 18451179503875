import { Context, useCallback, useContext, useMemo } from 'react';
import deepmerge from 'deepmerge';

import {
  ComponentStructure,
  ComponentStructureConfig,
  ComponentStyleConfig,
  GetStyleFn,
  StyleEntry,
  StyleSpec,
  Styling,
  Theme,
  VariantOverride,
  __ImplicitKeyOf,
} from './types';
import { type Interpolation, type Theme as EmotionTheme } from '@emotion/react';
import { nanoid } from 'nanoid';

declare const __DEV__: boolean;
declare const __IS_POST_BUILT__: boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function identity(a: any): any {
  return a;
}

export const makeDefineComponentStructure = <TTheme extends Theme>(
  themeContext: Context<TTheme | undefined>
): Styling<TTheme> => {
  const defineComponentStructure = <
    TComponentName extends string,
    TParts extends readonly string[],
    TVariants extends readonly string[],
    TSizes extends readonly string[]
  >(
    componentName: TComponentName,
    structureConfig: ComponentStructureConfig<TParts, TVariants, TSizes> = {
      parts: [],
      variants: [],
      sizes: [],
    }
  ): ComponentStructure<TTheme, TComponentName, TParts, TVariants, TSizes> => {
    return {
      get componentName() {
        return componentName;
      },
      get config() {
        return structureConfig;
      },
      utils() {
        return [
          (componentStyleConfigFn, baseConfigFn) => {
            if (!baseConfigFn) {
              return componentStyleConfigFn;
            }
            return (theme) => {
              const original = baseConfigFn(theme);
              const extension = componentStyleConfigFn(theme);
              return deepmerge(original, extension);
            };
          },
          (options = {}, defaultComponentStyleConfigFn) => {
            const theme = useContext(themeContext);

            const finalConfig = useMemo(() => {
              const overrideFn = theme?.components[componentName];
              const overrideConfig = overrideFn ? overrideFn(theme) : {};
              const defaultConfig = theme
                ? defaultComponentStyleConfigFn(theme)
                : {};
              return deepmerge(
                defaultConfig,
                overrideConfig
              ) as ComponentStyleConfig<
                TTheme,
                TParts[number],
                TVariants[number],
                TSizes[number]
              >;
            }, [defaultComponentStyleConfigFn, theme]);

            const { size, variant } = options;

            const getStyles: GetStyleFn<TParts[number]> = useCallback(
              (part) => {
                if (
                  __DEV__ &&
                  __IS_POST_BUILT__ &&
                  process.env['JEST_WORKER_ID']
                ) {
                  return null;
                }
                const serializedStyles = [
                  finalConfig.base?.[part] ?? null,
                  variant ? finalConfig.variants?.[variant]?.[part] : null,
                  size ? finalConfig.sizes?.[size]?.[part] : null,
                ] as Interpolation<EmotionTheme>;
                return serializedStyles;
              },
              [finalConfig, size, variant]
            );

            return { getStyles };
          },
        ];
      },
      parts(...parts) {
        return defineComponentStructure(componentName, {
          ...structureConfig,
          parts,
        });
      },
      variants(...variants) {
        return defineComponentStructure(componentName, {
          ...structureConfig,
          variants,
        });
      },
      sizes(...sizes) {
        return defineComponentStructure(componentName, {
          ...structureConfig,
          sizes,
        });
      },
    };
  };

  // a simpler variant to be used by library consumers
  const defineStyles = <
    T extends StyleSpec<TTheme>,
    V extends VariantOverride<TTheme, T>
  >(
    ...args:
      | [name: string, structure: T, variant?: V]
      | [structure: T, variant?: V]
  ) => {
    const name =
      typeof args[0] === 'string' ? args[0] : `gds.component@${nanoid()}`;

    const structure = (typeof args[0] === 'string' ? args[1] : args[0]) as T;
    const variant = (typeof args[0] === 'string' ? args[2] : args[1]) as
      | V
      | undefined;

    const componentStructure = defineComponentStructure(name, {
      parts: Object.keys(structure) as __ImplicitKeyOf<T>[],
      variants:
        variant === undefined
          ? []
          : (Object.keys(variant) as __ImplicitKeyOf<V>[]),
      sizes: [],
    });

    const [defineComponentStyles, useComponentStyles] =
      componentStructure.utils();

    const mapStructure = (
      structure: { [k in __ImplicitKeyOf<T>]?: StyleEntry<TTheme> },
      theme: TTheme
    ) =>
      Object.entries(structure).map((entry) => {
        switch (typeof entry[1]) {
          case 'function':
            return [entry[0], entry[1]?.(theme)];
          default:
            return entry;
        }
      });

    const defaultStyles = defineComponentStyles((theme) => ({
      base: Object.fromEntries(mapStructure(structure, theme)),
      variants: (variant
        ? Object.fromEntries(
            Object.entries(variant).map(([variant, entry]) => [
              variant,
              Object.fromEntries(mapStructure(entry, theme)),
            ])
          )
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {}) as any,
    }));
    const useStyles = (variant?: __ImplicitKeyOf<V>) =>
      useComponentStyles(
        { variant: variant as __ImplicitKeyOf<V> },
        defaultStyles
      );
    return { useStyles, defaultStyles, structure: componentStructure };
  };

  return { defineComponentStructure, defineStyles, serialize: identity };
};
