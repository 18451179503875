import { duxton } from '@gds-web-ui/design-tokens';
import { fontFamily } from '@gds-web-ui/styling';
import { buildTheme, capitalize } from './builder';

const defaults = /* @__PURE__ */ {
  fontFamily: {
    regular: fontFamily(duxton.gdsFontFamilySanomat),
    brand: fontFamily(duxton.gdsFontFamilyGrabCommunitySolid),
    brandInline: fontFamily(duxton.gdsFontFamilyGrabCommunityInline),
    inter: fontFamily(duxton.gdsFontFamilyInter),
    sanomat: fontFamily(duxton.gdsFontFamilySanomat),
  },
  spacing: {
    large: '24px',
    medium: '16px',
    small: '8px',
    none: '0px',
    tiny: '4px',
  },
  cornerRadius: '24px',
  elementColors: {},
  elevation: {},
  components: {},
};

export const defaultTheme = buildTheme('light', {
  ...defaults,
  backgroundColors: (entry) =>
    duxton.light[`duxtonColorBackground${capitalize(entry)}`],
  contentColors: (entry) => {
    return duxton.light[`duxtonColorContent${capitalize(entry)}`];
  },
  brandColors: (entry) => duxton.light[`duxtonColorBrand${capitalize(entry)}`],
  outlineColors: (entry) =>
    duxton.light[`duxtonColorOutline${capitalize(entry)}`],
  statesColors: (entry) =>
    duxton.light[`duxtonColorStatus${capitalize(entry)}`],
  typography: (entry) => duxton[`duxtonTextStyle${capitalize(entry)}`],
});
