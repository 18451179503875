import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';
import React, { createContext, useContext, useMemo } from 'react';

const env = Symbol('env');

type SafeAreaInsetValue = typeof env | number;

export interface Rect<T> {
  top: T;
  right: T;
  bottom: T;
  left: T;
  reset?: boolean;
}

export type SafeAreaInset = Rect<SafeAreaInsetValue> & {
  parent?: SafeAreaInset;
};

export type SafeAreaProps = Partial<Rect<number | undefined>> & {
  children?: React.ReactNode;
};

interface SafeAreaContextValue {
  inset: typeof env | SafeAreaInset;
  padding: Rect<CSSInterpolation>;
  margin: Rect<CSSInterpolation>;
}

const rootContextValue: SafeAreaContextValue = {
  inset: env,
  padding: {
    top: css({
      paddingTop: 'env(safe-area-inset-top)',
    }),
    right: css({
      paddingRight: 'env(safe-area-inset-right)',
    }),
    bottom: css({
      paddingBottom: 'env(safe-area-inset-bottom)',
    }),
    left: css({
      paddingLeft: 'env(safe-area-inset-left)',
    }),
  },
  margin: {
    top: css({
      marginTop: 'env(safe-area-inset-top)',
    }),
    right: css({
      marginRight: 'env(safe-area-inset-right)',
    }),
    bottom: css({
      marginBottom: 'env(safe-area-inset-bottom)',
    }),
    left: css({
      marginLeft: 'env(safe-area-inset-left)',
    }),
  },
};

function mergeSafeAreaValues(
  base: typeof env | SafeAreaInset,
  override?: Partial<Rect<number | undefined>>
): typeof env | SafeAreaInset {
  if (!override) {
    return base;
  }

  if (base === env) {
    return {
      top: override.top ?? env,
      right: override.right ?? env,
      bottom: override.bottom ?? env,
      left: override.left ?? env,
    };
  }

  let changed = 0;

  const top = override.top !== undefined ? ++changed && override.top : base.top;
  const right =
    override.right !== undefined ? ++changed && override.right : base.right;
  const bottom =
    override.bottom !== undefined ? ++changed && override.bottom : base.bottom;
  const left =
    override.left !== undefined ? ++changed && override.left : base.left;

  if (changed) {
    return { top, right, bottom, left, parent: base };
  }

  return base;
}

function buildValue(
  key: keyof Rect<unknown>,
  value: typeof env | number
): string {
  if (value === env) {
    return `env(safe-area-inset-${key})`;
  }
  return `${value}px`;
}

function buildRect(type: 'padding' | 'margin', inset: SafeAreaInset) {
  return {
    top: css({
      [`${type}Top`]: buildValue('top', inset.top),
    }),
    right: css({
      [`${type}Right`]: buildValue('right', inset.right),
    }),
    bottom: css({
      [`${type}Bottom`]: buildValue('bottom', inset.bottom),
    }),
    left: css({
      [`${type}Left`]: buildValue('left', inset.left),
    }),
  };
}

export function getValue(
  ctx: SafeAreaContextValue,
  key: keyof Rect<unknown>
): string {
  if (ctx.inset === env) {
    return buildValue(key, ctx.inset);
  } else {
    return buildValue(key, ctx.inset[key] as never);
  }
}

function buildSafeAreaInset(
  inset: typeof env | SafeAreaInset
): SafeAreaContextValue {
  if (inset === env) {
    return rootContextValue;
  }
  return {
    inset,
    padding: buildRect('padding', inset),
    margin: buildRect('margin', inset),
  };
}

export const SafeAreaContext = createContext(rootContextValue);

export const SafeArea = ({
  top,
  left,
  bottom,
  right,
  children,
}: SafeAreaProps) => {
  const parent = useContext(SafeAreaContext);
  const value = useMemo(() => {
    const inset = mergeSafeAreaValues(parent.inset, {
      top,
      left,
      bottom,
      right,
    });
    return buildSafeAreaInset(inset);
  }, [parent.inset, top, left, bottom, right]);

  return (
    <SafeAreaContext.Provider value={value}>
      {children}
    </SafeAreaContext.Provider>
  );
};

SafeArea.Ignore = function SafeArea__Ignore({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <SafeArea top={0} bottom={0} left={0} right={0}>
      {children}
    </SafeArea>
  );
};

SafeArea.Reset = function SafeArea__Reset({
  children,
}: {
  children?: React.ReactNode;
}) {
  const parent = useContext(SafeAreaContext);

  const value = useMemo(() => {
    if (parent.inset !== env && parent.inset.parent) {
      return buildSafeAreaInset(parent.inset.parent);
    }
    return buildSafeAreaInset(parent.inset);
  }, [parent]);

  return (
    <SafeAreaContext.Provider value={value}>
      {children}
    </SafeAreaContext.Provider>
  );
};

export function useSafeArea(
  mode: 'padding' | 'margin'
): Rect<CSSInterpolation> {
  const context = useContext(SafeAreaContext);
  return context[mode];
}

export function useSafeAreaValue(key: keyof Rect<unknown>) {
  const context = useContext(SafeAreaContext);
  return getValue(context, key);
}
