import {
  ReactNode,
  Ref,
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
  MutableRefObject,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import { useForkRef } from '@gds-web-ui/utils-fork-ref';
import { usePageScrollObserver } from './usePageScrollObserver';
import { Portal, PortalContainerOptions } from './usePortalContainer';
import { createContext } from '@gds-web-ui/utils-context';

type PopperMainPositions = 'top' | 'bottom' | 'left' | 'right';
export type PopperRealPosition =
  | `${PopperMainPositions}Start`
  | `${PopperMainPositions}End`
  | PopperMainPositions;
export type PopperPositionWithAuto = 'auto' | PopperRealPosition;
const BROWSER_EDGE_THRESHOLD = 16;
const defaultAutoPositionCheckOrder = [
  'top',
  'bottom',
  'topStart',
  'bottomStart',
  'topEnd',
  'bottomEnd',
  'left',
  'right',
  'leftStart',
  'rightStart',
  'leftEnd',
  'rightEnd',
] as const;

const calculateAnchorEdgePosition = (
  anchor: HTMLElement,
  popper: HTMLElement,
  position: PopperRealPosition,
  offset: number
): [number, number] => {
  const {
    left,
    top,
    height: anchorHeight,
    width: anchorWidth,
  } = anchor.getBoundingClientRect();
  const { width: popperWidth, height: popperHeight } =
    popper.getBoundingClientRect();
  const halfAnchorHeight = anchorHeight / 2.0;
  const halfAnchorWidth = anchorWidth / 2.0;
  const halfPopperHeight = popperHeight / 2.0;
  const halfPopperWidth = popperWidth / 2.0;

  let posX = left + window.scrollX + halfAnchorWidth;
  let posY = top + window.scrollY + halfAnchorHeight;

  if (position.startsWith('left') || position.startsWith('right')) {
    posY -= halfPopperHeight;
    if (position.endsWith('Start')) {
      posY -= halfAnchorHeight - halfPopperHeight;
    } else if (position.endsWith('End')) {
      posY += halfAnchorHeight - halfPopperHeight;
    }
    if (position.startsWith('right')) {
      posX += halfAnchorWidth + offset;
    } else {
      posX -= halfAnchorWidth + popperWidth + offset;
    }
  }
  if (position.startsWith('top') || position.startsWith('bottom')) {
    posX -= halfPopperWidth;
    if (position.endsWith('Start')) {
      posX -= halfAnchorWidth - halfPopperWidth;
    } else if (position.endsWith('End')) {
      posX += halfAnchorWidth - halfPopperWidth;
    }
    if (position.startsWith('bottom')) {
      posY += halfAnchorHeight + offset;
    } else {
      posY -= halfAnchorHeight + popperHeight + offset;
    }
  }

  return [posX, posY];
};

const setPopperPosition = (
  anchor: HTMLElement | null | undefined,
  popper: HTMLElement | null | undefined,
  position: PopperRealPosition,
  lastPosition: MutableRefObject<[number, number]>,
  offset: number
) => {
  if (!anchor || !popper) {
    return;
  }

  const [posX, posY] = calculateAnchorEdgePosition(
    anchor,
    popper,
    position,
    offset
  );

  lastPosition.current = [posX, posY];
  popper.style.top = `${posY}px`;
  popper.style.left = `${posX}px`;
};

const isElementPositionVisible = (element: HTMLElement | null) => {
  if (!element) {
    return false;
  }
  const rect = element.getBoundingClientRect();

  const conditions = {
    left: rect.left > BROWSER_EDGE_THRESHOLD,
    top: rect.top > BROWSER_EDGE_THRESHOLD,
    right:
      rect.right + BROWSER_EDGE_THRESHOLD <
      document.documentElement.clientWidth,
    bottom:
      rect.bottom + BROWSER_EDGE_THRESHOLD <
      document.documentElement.clientHeight,
  };

  return (
    conditions.top && conditions.bottom && conditions.left && conditions.right
  );
};

type PopperContextValue = {
  nestLevel: number;
};

const [PopperProvider, usePopperContext] = createContext<PopperContextValue>({
  name: 'PopperContext',
  strict: false,
});

export interface PopperProps {
  open: boolean;
  children: ReactNode;
}

export type PopperComponentProps = {
  position?: PopperPositionWithAuto | undefined;
  autoPositionOrder?: readonly PopperRealPosition[] | undefined;
  distanceFromAnchor?: number | undefined;
  defaultNestLevel?: number | undefined;
  zIndex?: number | undefined;
  anchorRef?: MutableRefObject<HTMLElement | null> | undefined;
} & Pick<PortalContainerOptions, 'containerRef' | 'inPlace'>;

export type PopperHookProps = PopperComponentProps & {
  forwardedRef?: Ref<HTMLDivElement> | undefined;
  portalName?: string | undefined;
  postOpenHook?:
    | ((args: {
        realPosition: PopperRealPosition;
        anchor: HTMLElement | null;
        popper: HTMLElement | null;
      }) => void)
    | undefined;
  repositionHook?:
    | ((args: {
        realPosition: PopperRealPosition;
        anchor: HTMLElement | null;
        popper: HTMLElement | null;
      }) => void)
    | undefined;
};

export const usePopper = ({
  forwardedRef,
  portalName = 'gds-popper',
  position = 'auto',
  distanceFromAnchor = 0,
  defaultNestLevel = 0,
  zIndex = 100,
  anchorRef: incomingAnchorRef,
  autoPositionOrder = defaultAutoPositionCheckOrder,
  postOpenHook,
  repositionHook,
  inPlace,
  containerRef,
}: PopperHookProps) => {
  const { nestLevel } = Object.assign(
    {
      nestLevel: defaultNestLevel,
    },
    usePopperContext()
  );
  const nestLevelRef = useRef(nestLevel);
  nestLevelRef.current = nestLevel;

  const bounds = incomingAnchorRef?.current?.getBoundingClientRect();
  const lastPosition = useRef<[number, number]>([
    bounds?.left ?? 0,
    bounds?.top ?? 0,
  ]);

  const popperRef = useRef<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLElement | null>(
    incomingAnchorRef?.current ?? null
  );
  const combinedAnchorRef = useForkRef(anchorRef, incomingAnchorRef) as (
    value: HTMLElement | null
  ) => void;

  useEffect(() => {
    anchorRef.current = incomingAnchorRef?.current ?? anchorRef.current;
  }, [incomingAnchorRef]);

  const internalContentRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useForkRef(forwardedRef, internalContentRef);

  const [realPosition, setRealPosition] = useState<PopperRealPosition>(
    position === 'auto' ? autoPositionOrder[0] ?? 'top' : position
  );
  const realPositionRef = useRef(realPosition);
  realPositionRef.current = realPosition;

  const repositionHookRef = useRef<typeof repositionHook>(repositionHook);
  repositionHookRef.current = repositionHook;

  const handleReposition = useCallback(() => {
    const _realPosition = realPositionRef.current;
    if (
      !anchorRef.current ||
      !internalContentRef.current ||
      !popperRef.current
    ) {
      return;
    }

    if (position !== 'auto' && position !== _realPosition) {
      setPopperPosition(
        anchorRef.current,
        popperRef.current,
        position,
        lastPosition,
        distanceFromAnchor
      );
      setRealPosition(position);
      repositionHookRef.current?.({
        realPosition: position,
        anchor: anchorRef.current,
        popper: popperRef.current,
      });
      return;
    }

    if (position !== 'auto') {
      setPopperPosition(
        anchorRef.current,
        popperRef.current,
        position,
        lastPosition,
        distanceFromAnchor
      );
      repositionHookRef.current?.({
        realPosition: position,
        anchor: anchorRef.current,
        popper: popperRef.current,
      });
      return;
    }

    internalContentRef.current.setAttribute('data-position', _realPosition);
    setPopperPosition(
      anchorRef.current,
      popperRef.current,
      _realPosition,
      lastPosition,
      distanceFromAnchor
    );
    if (isElementPositionVisible(popperRef.current)) {
      repositionHookRef.current?.({
        realPosition: _realPosition,
        anchor: anchorRef.current,
        popper: popperRef.current,
      });
      return;
    }

    const [originalTop, originalLeft] = [
      parseInt(popperRef.current.style.top, 10),
      parseInt(popperRef.current.style.left, 10),
    ];

    for (let i = 0; i < autoPositionOrder.length; i += 1) {
      const checkPosition = autoPositionOrder[i] || 'top';
      if (checkPosition === _realPosition) {
        continue;
      }
      internalContentRef.current.setAttribute('data-position', checkPosition);
      setPopperPosition(
        anchorRef.current,
        popperRef.current,
        checkPosition,
        lastPosition,
        distanceFromAnchor
      );
      if (isElementPositionVisible(popperRef.current)) {
        setRealPosition(checkPosition);
        repositionHookRef.current?.({
          realPosition: checkPosition,
          anchor: anchorRef.current,
          popper: popperRef.current,
        });
        return;
      }
    }

    internalContentRef.current.setAttribute('data-position', _realPosition);
    popperRef.current.style.top = `${originalTop}px`;
    popperRef.current.style.left = `${originalLeft}px`;
    lastPosition.current = [originalLeft, originalTop];
    repositionHookRef.current?.({
      realPosition: _realPosition,
      anchor: anchorRef.current,
      popper: popperRef.current,
    });
  }, [position, distanceFromAnchor, autoPositionOrder]);
  const postOpenHookRef = useRef<typeof postOpenHook>(postOpenHook);
  postOpenHookRef.current = postOpenHook;

  const Popper = useMemo(() => {
    return function Popper({ children, open }: PopperProps) {
      const flagRef = useRef<boolean>(false);

      const handleRepositionDebounce = useCallback(() => {
        if (flagRef.current || !open) {
          return;
        }
        flagRef.current = true;
        requestAnimationFrame(() => {
          handleReposition();
          flagRef.current = false;
        });
      }, [open]);

      usePageScrollObserver(window, handleRepositionDebounce);

      // window resize
      useResizeObserver({
        ref: document.body,
        onResize: handleRepositionDebounce,
      });

      // popper resize
      useResizeObserver({
        ref: internalContentRef,
        onResize: handleRepositionDebounce,
      });

      // anchor resize
      useResizeObserver({
        ref: anchorRef.current,
        onResize: handleRepositionDebounce,
      });
      const composedPostOpenHook = useCallback(() => {
        postOpenHookRef.current?.({
          realPosition,
          anchor: anchorRef.current,
          popper: popperRef.current,
        });
        setTimeout(() => {
          handleRepositionDebounce();
        }, nestLevelRef.current);
      }, [handleRepositionDebounce]);

      if (!open) {
        return null;
      }

      return (
        <Portal
          prefix={portalName}
          inPlace={inPlace}
          containerRef={containerRef}
        >
          <div
            ref={(el) => {
              popperRef.current = el;
              composedPostOpenHook();
            }}
            css={{
              pointerEvents: 'all',
              position: 'absolute',
              width: 'fit-content',
              top: lastPosition.current[1],
              left: lastPosition.current[0],
              zIndex:
                zIndex +
                (nestLevelRef.current === undefined ? 0 : nestLevelRef.current),
            }}
          >
            <PopperProvider
              value={{
                nestLevel:
                  (nestLevelRef.current === undefined
                    ? 0
                    : nestLevelRef.current) + 1,
              }}
            >
              {children}
            </PopperProvider>
          </div>
        </Portal>
      );
    };
  }, [
    portalName,
    zIndex,
    realPosition,
    handleReposition,
    inPlace,
    containerRef,
  ]);
  return {
    Popper,
    realPosition,
    contentRef,
    anchorRef: combinedAnchorRef,
  };
};
