import type { TextStyle } from './typography';

/** @deprecated */
export const duxtonTextStyleLogoTypeInline = {
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 720,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleLogoTypeSolid = {
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleHeading01 = {
  fontSize: '40px',
  lineHeight: '48px',
  fontWeight: 400,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleHeading02 = {
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleHeading03 = {
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleHeading04 = {
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleHeading05 = {
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleBody01Medium = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleBody01SemiBold = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleBody02Medium = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleBody02SemiBold = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleCallout01 = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleCallout02 = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleCallout03 = {
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleMetadata01 = {
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 400,
} as const satisfies TextStyle;

/** @deprecated */
export const duxtonTextStyleMetadata02 = {
  fontSize: '10px',
  lineHeight: '12px',
  fontWeight: 400,
} as const satisfies TextStyle;

/** @deprecated */
export const gdsColorWhite = '#ffffff';
/** @deprecated */
export const gdsColorBlack = '#000000';
/** @deprecated */
export const gdsColorNeutral50 = '#f5f5f5';
/** @deprecated */
export const gdsColorNeutral75 = '#e8e8e8';
/** @deprecated */
export const gdsColorNeutral100 = '#dbdbdb';
/** @deprecated */
export const gdsColorNeutral200 = '#bfbfbf';
/** @deprecated */
export const gdsColorNeutral300 = '#a3a3a3';
/** @deprecated */
export const gdsColorNeutral400 = '#8c8c8c';
/** @deprecated */
export const gdsColorNeutral500 = '#707070';
/** @deprecated */
export const gdsColorNeutral600 = '#575757';
/** @deprecated */
export const gdsColorNeutral700 = '#3d3d3d';
/** @deprecated */
export const gdsColorNeutral800 = '#292929';
/** @deprecated */
export const gdsColorNeutral900 = '#1a1a1a';
/** @deprecated */
export const gdsColorNeutralCool50 = '#f3f6fb';
/** @deprecated */
export const gdsColorNeutralCool75 = '#e5e9f0';
/** @deprecated */
export const gdsColorNeutralCool100 = '#d7dce4';
/** @deprecated */
export const gdsColorNeutralCool200 = '#bac0c9';
/** @deprecated */
export const gdsColorNeutralCool300 = '#9fa4ad';
/** @deprecated */
export const gdsColorNeutralCool400 = '#868d95';
/** @deprecated */
export const gdsColorNeutralCool500 = '#697078';
/** @deprecated */
export const gdsColorNeutralCool600 = '#50565e';
/** @deprecated */
export const gdsColorNeutralCool700 = '#383e43';
/** @deprecated */
export const gdsColorNeutralCool800 = '#242a2e';
/** @deprecated */
export const gdsColorNeutralCool900 = '#161a1d';
/** @deprecated */
export const gdsColorSlate50 = '#f0f2ff';
/** @deprecated */
export const gdsColorSlate75 = '#e1e7f6';
/** @deprecated */
export const gdsColorSlate100 = '#d2dcee';
/** @deprecated */
export const gdsColorSlate200 = '#b2c0d7';
/** @deprecated */
export const gdsColorSlate300 = '#94a4c2';
/** @deprecated */
export const gdsColorSlate400 = '#798aaf';
/** @deprecated */
export const gdsColorSlate500 = '#606f90';
/** @deprecated */
export const gdsColorSlate600 = '#485774';
/** @deprecated */
export const gdsColorSlate700 = '#303d55';
/** @deprecated */
export const gdsColorSlate800 = '#1f293d';
/** @deprecated */
export const gdsColorSlate900 = '#131a25';
/** @deprecated */
export const gdsColorGreen50 = '#d9fcde';
/** @deprecated */
export const gdsColorGreen75 = '#c6f4cc';
/** @deprecated */
export const gdsColorGreen100 = '#b1eaba';
/** @deprecated */
export const gdsColorGreen200 = '#78d389';
/** @deprecated */
export const gdsColorGreen300 = '#3cb95a';
/** @deprecated */
export const gdsColorGreen400 = '#00b14f';
/** @deprecated */
export const gdsColorGreen500 = '#00804a';
/** @deprecated */
export const gdsColorGreen600 = '#03633e';
/** @deprecated */
export const gdsColorGreen700 = '#005339';
/** @deprecated */
export const gdsColorGreen800 = '#002e27';
/** @deprecated */
export const gdsColorGreen900 = '#001e1f';
/** @deprecated */
export const gdsColorGreenA50 = '#e8fccf';
/** @deprecated */
export const gdsColorGreenA75 = '#e1f689';
/** @deprecated */
export const gdsColorGreenA100 = '#d3f035';
/** @deprecated */
export const gdsColorGreenA200 = '#8cd421';
/** @deprecated */
export const gdsColorGreenA300 = '#5ab828';
/** @deprecated */
export const gdsColorBlue50 = '#ebfaff';
/** @deprecated */
export const gdsColorBlue75 = '#ccf2ff';
/** @deprecated */
export const gdsColorBlue100 = '#b3ebff';
/** @deprecated */
export const gdsColorBlue200 = '#86d8f9';
/** @deprecated */
export const gdsColorBlue300 = '#3ab5ee';
/** @deprecated */
export const gdsColorBlue400 = '#1495e6';
/** @deprecated */
export const gdsColorBlue500 = '#136fd8';
/** @deprecated */
export const gdsColorBlue600 = '#0b54a8';
/** @deprecated */
export const gdsColorBlue700 = '#0a3d71';
/** @deprecated */
export const gdsColorBlue800 = '#0c2b45';
/** @deprecated */
export const gdsColorBlue900 = '#0b1b23';
/** @deprecated */
export const gdsColorOrange50 = '#fff4eb';
/** @deprecated */
export const gdsColorOrange75 = '#ffe3cc';
/** @deprecated */
export const gdsColorOrange100 = '#ffd3ad';
/** @deprecated */
export const gdsColorOrange200 = '#ffb570';
/** @deprecated */
export const gdsColorOrange300 = '#ff8404';
/** @deprecated */
export const gdsColorOrange400 = '#f76708';
/** @deprecated */
export const gdsColorOrange500 = '#ca3e07';
/** @deprecated */
export const gdsColorOrange600 = '#9e3105';
/** @deprecated */
export const gdsColorOrange700 = '#712304';
/** @deprecated */
export const gdsColorOrange800 = '#4f1803';
/** @deprecated */
export const gdsColorOrange900 = '#310f02';
/** @deprecated */
export const gdsColorYellow50 = '#fff9c0';
/** @deprecated */
export const gdsColorYellow75 = '#ffec8f';
/** @deprecated */
export const gdsColorYellow100 = '#ffd933';
/** @deprecated */
export const gdsColorYellow150 = '#ffc905';
/** @deprecated */
export const gdsColorYellow200 = '#ffbc05';
/** @deprecated */
export const gdsColorYellow250 = '#faab00';
/** @deprecated */
export const gdsColorYellow300 = '#f09800';
/** @deprecated */
export const gdsColorYellow350 = '#e58a00';
/** @deprecated */
export const gdsColorYellow400 = '#cc7a00';
/** @deprecated */
export const gdsColorYellow500 = '#a55f03';
/** @deprecated */
export const gdsColorYellow600 = '#824703';
/** @deprecated */
export const gdsColorRed50 = '#fdf2f2';
/** @deprecated */
export const gdsColorRed75 = '#ffe2df';
/** @deprecated */
export const gdsColorRed100 = '#ffd2cc';
/** @deprecated */
export const gdsColorRed200 = '#ffa799';
/** @deprecated */
export const gdsColorRed300 = '#ff7f6b';
/** @deprecated */
export const gdsColorRed400 = '#f55538';
/** @deprecated */
export const gdsColorRed500 = '#d42e1c';
/** @deprecated */
export const gdsColorRed600 = '#ac1e11';
/** @deprecated */
export const gdsColorRed700 = '#79150c';
/** @deprecated */
export const gdsColorRed800 = '#530e08';
/** @deprecated */
export const gdsColorRed900 = '#380a06';
/** @deprecated */
export const gdsColorIntegrityTeal50 = '#defbfc';
/** @deprecated */
export const gdsColorIntegrityTeal75 = '#a4f6f9';
/** @deprecated */
export const gdsColorIntegrityTeal100 = '#61eff5';
/** @deprecated */
export const gdsColorIntegrityTeal200 = '#0fd4db';
/** @deprecated */
export const gdsColorIntegrityTeal300 = '#01b5bc';
/** @deprecated */
export const gdsColorIntegrityTeal400 = '#009da3';
/** @deprecated */
export const gdsColorIntegrityTeal500 = '#008388';
/** @deprecated */
export const gdsColorIntegrityTeal600 = '#066165';
/** @deprecated */
export const gdsColorIntegrityTeal700 = '#0b4446';
/** @deprecated */
export const gdsColorIntegrityTeal800 = '#0f2d2f';
/** @deprecated */
export const gdsColorIntegrityTeal900 = '#0b1d1e';
/** @deprecated */
export const gdsColorDuxtonBlue50 = '#eef9f9';
/** @deprecated */
export const gdsColorDuxtonBlue75 = '#d2edea';
/** @deprecated */
export const gdsColorDuxtonBlue100 = '#b8e5df';
/** @deprecated */
export const gdsColorDuxtonBlue200 = '#84d2ca';
/** @deprecated */
export const gdsColorDuxtonBlue300 = '#4ab5aa';
/** @deprecated */
export const gdsColorDuxtonBlue400 = '#2d9f94';
/** @deprecated */
export const gdsColorDuxtonBlue500 = '#277c74';
/** @deprecated */
export const gdsColorDuxtonBlue600 = '#206059';
/** @deprecated */
export const gdsColorDuxtonBlue700 = '#184440';
/** @deprecated */
export const gdsColorDuxtonBlue800 = '#0f2e2b';
/** @deprecated */
export const gdsColorDuxtonBlue900 = '#091a19';
/** @deprecated */
export const duxtonColorBrandPrimaryDefault = '#00b14f';
/** @deprecated */
export const duxtonColorBrandPrimaryAlt = '#005339';
/** @deprecated */
export const duxtonColorBrandSecondaryAlt = '#eef9f9';
/** @deprecated */
export const duxtonColorBackgroundLightPrimary = '#ffffff';
/** @deprecated */
export const duxtonColorBackgroundLightSecondary = '#f5f5f5';
/** @deprecated */
export const duxtonColorBackgroundLightTranslucent =
  'rgba(255, 255, 255, 0.24)';
/** @deprecated */
export const duxtonColorBackgroundDarkPrimary = '#1a1a1a';
/** @deprecated */
export const duxtonColorBackgroundDarkSecondary = '#3d3d3d';
/** @deprecated */
export const duxtonColorBackgroundDarkTranslucent = 'rgba(26, 26, 26, 0.8)';
/** @deprecated */
export const duxtonColorTextPrimary = '#1a1a1a';
/** @deprecated */
export const duxtonColorTextSecondary = '#3d3d3d';
/** @deprecated */
export const duxtonColorTextTertiary = '#707070';
/** @deprecated */
export const duxtonColorTextPlaceholder = '#a3a3a3';
/** @deprecated */
export const duxtonColorTextDisabled = '#bfbfbf';
/** @deprecated */
export const duxtonColorTextLink = '#136fd8';
/** @deprecated */
export const duxtonColorTextWhite = '#ffffff';
/** @deprecated */
export const duxtonColorCtaStatusPositive = '#00b14f';
/** @deprecated */
export const duxtonColorCtaStatusInformative = '#136fd8';
/** @deprecated */
export const duxtonColorCtaStatusNotice = '#f09800';
/** @deprecated */
export const duxtonColorCtaStatusNegative = '#f55538';
