import { CSSInterpolation } from '@emotion/serialize';
import { createLibraryComponent } from '@gds-web-ui/core';
import {
  SizeOf,
  VariantOf,
  defineComponentStructure,
} from '@gds-web-ui/duxton-theme';
import { ReactNode } from 'react';
import { TextInternal, TextInternalProps } from './text-internal';

declare const __DEV__: boolean;

export const bodyStructure = defineComponentStructure('mobile.typography.body')
  .parts('root', 'bolder')
  .variants('regular', 'medium', 'semibold')
  .sizes('1', '2');

export const [defineBodyStyles, useBodyStyles] = bodyStructure.utils();

export const defaultBodyStyles = defineBodyStyles(
  ({ fontFamily, typography }) => ({
    base: {
      root: {
        fontFamily: fontFamily.regular,
        userSelect: 'none',
        cursor: 'default',
        margin: 0,
      },
    },
    sizes: {
      1: {
        root: [
          typography.body01Regular,
          {
            'strong, b': typography.body01Bolder,
          },
        ],
        bolder: typography.body01Bolder,
      },
      2: {
        root: [
          typography.body02Regular,
          {
            'strong, b': typography.body02Bolder,
          },
        ],
        bolder: typography.body02Bolder,
      },
    },
  })
);

export interface BodyPropsInternal
  extends Omit<TextInternalProps, 'component'> {
  size: SizeOf<typeof bodyStructure>;
  children?: ReactNode;
  /** @deprecated use `bolder` instead */
  variant?: VariantOf<typeof bodyStructure>;
  bolder?: boolean | undefined;
}

export type BodyProps = Omit<BodyPropsInternal, 'size'>;

export const Body = createLibraryComponent<
  BodyPropsInternal,
  HTMLParagraphElement | HTMLSpanElement
>(function Body({
  size,
  children,
  variant = 'regular',
  UNSAFE_className,
  forwardedRef,
  bolder,
  ...rest
}) {
  if (__DEV__ && variant !== 'regular') {
    console.warn(
      'variant prop in `Body` component is deprecated. Use `bolder` instead.'
    );
  }

  const { getStyles } = useBodyStyles({ variant, size }, defaultBodyStyles);

  const rootStyles: CSSInterpolation = getStyles('root') as CSSInterpolation;

  const isBolder = bolder || variant !== 'regular';

  return (
    <TextInternal
      {...rest}
      style={[rest.style, rootStyles, isBolder && getStyles('bolder')]}
      component="p"
      className={UNSAFE_className}
      ref={forwardedRef}
    >
      {children}
    </TextInternal>
  );
});

export const Body01 = createLibraryComponent<
  BodyProps,
  HTMLParagraphElement & HTMLSpanElement
>(function Body01({ forwardedRef, ...props }) {
  return <Body {...props} size="1" ref={forwardedRef} />;
});

export const Body02 = createLibraryComponent<
  BodyProps,
  HTMLParagraphElement & HTMLSpanElement
>(function Body02({ forwardedRef, ...props }) {
  return <Body {...props} size="2" ref={forwardedRef} />;
});
