import { defineComponentStructure } from '@gds-web-ui/duxton-theme';
import { Heading03 } from '@gds-web-ui/duxton-typography';

export interface AppBarTitleProps {
  children?: string;
}

export const appBarTitleStructure = defineComponentStructure(
  'mobile.appbar.title'
)
  .parts('root')
  .variants('default')
  .sizes('default');

export const [defineAppBarTitleStyles, useAppBarTitleStyles] =
  appBarTitleStructure.utils();

export const defaultAppBarThemeStyles = defineAppBarTitleStyles(
  ({ contentColors }) => ({
    base: {
      root: {
        color: contentColors.default,
      },
    },
  })
);

export const AppBarLargeTitle = ({ children }: AppBarTitleProps) => {
  const { getStyles } = useAppBarTitleStyles(
    { variant: 'default', size: 'default' },
    defaultAppBarThemeStyles
  );
  return (
    <Heading03 shrinkToFit css={getStyles('root')} aria-level={1}>
      {children}
    </Heading03>
  );
};
