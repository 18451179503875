import { StructuredOutputParser } from "langchain/output_parsers";
import { z } from "zod";

export const parser = StructuredOutputParser.fromZodSchema(
  z.object({
    //TODO: modify the describe base on diff feature
    text_suggestions: z.array(
      z.string().describe("suggested text for advertisement, within 4-10 words")
    ),
    color_suggestions: z.array(
      z
        .object({
          background_color: z
            .string()
            .describe(
              "suggested background color for advertisement, in hex format"
            ),
          text_color: z
            .string()
            .describe("suggested text color for advertisement, in hex format"),
        })
        .describe(
          "suggested color pairs for advertisement, background color should be in contrast, visually appealing and readable. Both color should be in hex format and should look good on the background image"
        )
    ),
  })
);
