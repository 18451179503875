import { css } from '@emotion/react';
import { Body01, Callout01 } from '@gds-web-ui/duxton-typography';

export type ButtonContentIconPosition = 'start' | 'inline';

export interface ButtonContentProps {
  cta?: string | undefined;
  label?: string | undefined;
  icon?: string | JSX.Element | undefined;
  iconPosition?: ButtonContentIconPosition | undefined;
}

export const ButtonContentStyles = {
  iconContainer: css({
    width: '24px',
    height: '24px',
    img: {
      verticalAlign: 'top',
      width: '24px',
      height: '24px',
      objectFit: 'contain',
    },
  }),
  container: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'center',
    flex: 1,
  }),
  gapIcon: css({
    gap: '8px',
  }),
  iconPositioningStart: css({
    justifyContent: 'flex-start',
    span: {
      flex: 1,
      textAlign: 'center',
    },
  }),
};

export const ButtonContent = ({
  cta,
  label,
  icon,
  iconPosition,
}: ButtonContentProps) => {
  return (
    <div
      css={[
        ButtonContentStyles.container,
        label ? null : ButtonContentStyles.gapIcon,
        iconPosition === 'start' && ButtonContentStyles.iconPositioningStart,
      ]}
    >
      {icon && (
        <div css={ButtonContentStyles.iconContainer}>
          {typeof icon === 'string' ? <img src={icon} alt="" /> : icon}
        </div>
      )}
      <Callout01>{cta}</Callout01>
      {label && (
        <>
          <Body01 span>&bull;</Body01>
          <Body01 span>{label}</Body01>
        </>
      )}
    </div>
  );
};
