/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { isSome } from '../utils/common';

type ReactChild = string | number | JSX.Element;

export interface OptionalProps {
  predicate: boolean;
  else?: ReactChild | ReactChild[] | undefined;
  children: ReactChild | ReactChild[];
}

export interface OptionalTypeRestrictProps<T, U extends T> {
  predicate: (value: T) => value is U;
  value: T;
  else?: ReactChild | ReactChild[] | undefined;
  children: (value: U) => ReactChild | ReactChild[];
}

export interface OptionalSomeProps<T> {
  predicate?: undefined;
  some: T | undefined;
  else?: ReactChild | ReactChild[] | undefined;
  children: (value: T) => ReactChild | ReactChild[];
}

const TypedOptional = <T, U extends T>({
  predicate,
  children,
  value,
  else: elseElement,
}: OptionalTypeRestrictProps<T, U>) => {
  if (predicate(value)) {
    return <>{children(value)}</>;
  }
  if (elseElement) {
    return <>{elseElement}</>;
  }
  return <></>;
}

const BooleanOptional = ({
  predicate,
  children,
  else: elseElement,
}: OptionalProps) => {
  if (predicate) {
    return <>{children}</>;
  }
  if (elseElement) {
    return <>{elseElement}</>;
  }
  return <></>;
};

export const Optional = <T, U extends T>(
  props: OptionalProps | OptionalTypeRestrictProps<T, U> | OptionalSomeProps<T>
) => {
  switch (props.predicate) {
    case true:
    case false: {
      const { children: bc, ...bp } = props;
      return <BooleanOptional {...bp}>{bc}</BooleanOptional>;
    }
    case undefined: {
      const { children, some, else: elseBlock } = props;
      return (
        <TypedOptional predicate={isSome} else={elseBlock} value={some}>
          {children}
        </TypedOptional>
      );
    }

    default: {
      const { children: tc, ...tp } = props;
      return <TypedOptional {...tp}>{tc}</TypedOptional>;
    }
  }
};
