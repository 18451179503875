import {
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react';

export interface UseContextFunction<T> {
  (): T | undefined
  (merge: (fromContext: T | undefined) => T): T
}

export type CreateContextOptions<ContextValue> = {
  /**
   * Name of the context being created
   */
  name?: string | undefined;

  /**
   * The name of the hook provided for this context
   * @example useRadioGroupContext
   */
  hookName?: string | undefined;

  /**
   * The name of the provider provided for this context
   * @example '<RadioGroup />'
   */
  providerName?: string | undefined;

  /**
   * If `true`, an error will be thrown if the hook receives `undefined` value,
   * suggesting that it may be possible that the hook was called from outside of the provider.
   * @default true
   */
  strict?: boolean | undefined;

  /**
   * Default value of the context.
   * @default undefined
   */
  defaultValue?: ContextValue;

  /**
   * Error message to use when the hook was called and it received the `undefined` value.
   * If not provided, a default message will be used.
   */
  errorMessage?: string | undefined;
};

/**
 * Create a React Context
 *
 * @param options Create context options
 * @returns Returns the provider, context hook and context itself
 */
export const createContext = <ContextValue,>({
  name,
  hookName = 'useContext',
  providerName = 'Provider',
  strict = true,
  defaultValue = undefined,
  errorMessage,
}: CreateContextOptions<ContextValue>) => {
  const context = createReactContext<ContextValue | undefined>(defaultValue);

  context.displayName = name;

  function useContext() {
    const value = useReactContext(context);
    if (value === undefined && strict) {
      const error = new Error(
        errorMessage ??
          `${hookName} got \`undefined\`. Did you forget to wrap your component with \`${providerName}\`?`
      );
      error.name = 'ContextError';
      Error.captureStackTrace?.(error, useContext);
      throw error;
    }
    return value;
  }

  return [context.Provider, useContext, context] as const;
};
