import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import { EmotionCacheProvider } from './emotionCacheProvider';
import { Option } from './types';

export interface LibraryComponentOptions {
  displayName?: string;
}

type UnsafeStylingProps = {
  UNSAFE_className?: Option<string>;
};

export type LibraryComponentProps<TProps, TRef> = TProps &
  UnsafeStylingProps & { forwardedRef?: ForwardedRef<TRef> };

export type GenericLibraryComponentProps<TProps, TRef> = PropsWithoutRef<
  TProps & UnsafeStylingProps
> &
  RefAttributes<TRef>;

/**
 * A LibraryComponent describes a GDS Web UI component. It takes care of
 * the following under the hood:
 * 1. exposing underlying DOM Element / Class Component / Imperative handle as `ref`
 * 1. allowing className override via `UNSAFE_className` prop
 */
export type LibraryComponent<TProps, TRef> = ForwardRefExoticComponent<
  GenericLibraryComponentProps<TProps, TRef>
>;

export function createLibraryComponent<TProps, TRef>(
  Component: ComponentType<LibraryComponentProps<TProps, TRef>>,
  options?: LibraryComponentOptions
): LibraryComponent<TProps, TRef>;

/**
 * Use this variant if you want to further restrict your exposed props
 * @template TProps the internal props used to define your component
 * @template TExportedProps the props that you expose to your consumers
 */
export function createLibraryComponent<
  TProps,
  TExportedProps extends TProps,
  TRef
>(
  Component: ComponentType<LibraryComponentProps<TProps, TRef>>,
  options?: LibraryComponentOptions
): LibraryComponent<TExportedProps, TRef> & { __internalProps: TProps };
export function createLibraryComponent<TProps, TRef>(
  Component: ComponentType<LibraryComponentProps<TProps, TRef>>,
  options?: LibraryComponentOptions
) {
  const component = forwardRef<TRef, TProps & UnsafeStylingProps>(
    (props, ref) => {
      return (
        <EmotionCacheProvider>
          <Component {...props} forwardedRef={ref} />
        </EmotionCacheProvider>
      );
    }
  );

  const displayName =
    options?.displayName || Component.displayName || Component.name;
  if (displayName) {
    return Object.assign(component, { displayName });
  }

  return component;
}
