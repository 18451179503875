import { createLibraryComponent } from '@gds-web-ui/core';
import {
  SizeOf,
  VariantOf,
  defineComponentStructure,
} from '@gds-web-ui/duxton-theme';
import { ReactNode } from 'react';
import { TextInternal, TextInternalProps } from './text-internal';

declare const __DEV__: boolean;

export const headingStructure = defineComponentStructure(
  'mobile.typography.heading'
)
  .parts('root', 'bolder')
  .variants('default', 'medium', 'regular')
  .sizes('1', '2', '3', '4', '5');

export const [defineHeadingStyles, useHeadingStyles] = headingStructure.utils();

export const defaultHeadingStyles = defineHeadingStyles(
  ({ fontFamily, typography }) => ({
    base: {
      root: {
        fontFamily: fontFamily.regular,
        userSelect: 'none',
        cursor: 'default',
        margin: 0,
      },
    },
    variants: {
      medium: {
        root: { fontWeight: 500 },
      },
      regular: {
        root: { fontWeight: 400 },
      },
    },
    sizes: {
      1: {
        root: [
          typography.heading01Regular,
          {
            'b, strong': typography.heading01Bolder,
          },
        ],
        bolder: typography.heading01Bolder,
      },
      2: {
        root: typography.heading02Bolder,
        bolder: typography.heading02Bolder,
      },
      3: {
        root: typography.heading03Bolder,
        bolder: typography.heading03Bolder,
      },
      4: {
        root: typography.heading04Bolder,
        bolder: typography.heading04Bolder,
      },
      5: {
        root: typography.heading05Bolder,
        bolder: typography.heading05Bolder,
      },
    },
  })
);

export interface HeadingPropsInternal
  extends Omit<TextInternalProps, 'component'> {
  size: SizeOf<typeof headingStructure>;
  children?: ReactNode;
  variant?: VariantOf<typeof headingStructure>;
  bolder?: boolean | undefined;
  ignoreAriaRoleAndLevel?: boolean;
}

export type HeadingProps = Omit<HeadingPropsInternal, 'size' | 'variant'>;

export interface Heading01Props extends HeadingProps {
  /** @deprecated */
  variant?: VariantOf<typeof headingStructure>;
  bolder?: boolean | undefined;
}

export const Heading = createLibraryComponent<
  HeadingPropsInternal,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading({
  children,
  variant = 'default',
  forwardedRef,
  UNSAFE_className,
  ignoreAriaRoleAndLevel,
  size,
  bolder,
  ...attr
}) {
  const { getStyles } = useHeadingStyles(
    { variant: 'default', size },
    defaultHeadingStyles
  );

  const isBolder = bolder || variant === 'medium';

  if (__DEV__ && variant !== 'default') {
    console.warn(
      'variant prop in Heading is deprecated. Use `bolder` in Heading01 instead.'
    );
  }

  const props = {
    ...attr,
    style: [attr.style, getStyles('root'), isBolder && getStyles('bolder')],
    className: UNSAFE_className,
    ref: forwardedRef,
  };

  // add ARIA roles and levels
  if (
    !attr.role &&
    (attr.shrinkToFit || attr.span) &&
    !ignoreAriaRoleAndLevel
  ) {
    props.role = 'heading';
    if (!attr['aria-level']) {
      props['aria-level'] = +size;
    }
  }

  return (
    <TextInternal {...props} component={`h${size}`}>
      {children}
    </TextInternal>
  );
});

export const Heading01 = createLibraryComponent<
  Heading01Props,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading01({ variant = 'regular', forwardedRef, ...props }) {
  return <Heading {...props} variant={variant} size="1" ref={forwardedRef} />;
});

export const Heading02 = createLibraryComponent<
  HeadingProps,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading02({ forwardedRef, ...props }) {
  return <Heading {...props} size="2" ref={forwardedRef} />;
});

export const Heading03 = createLibraryComponent<
  HeadingProps,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading03({ forwardedRef, ...props }) {
  return <Heading {...props} size="3" ref={forwardedRef} />;
});

export const Heading04 = createLibraryComponent<
  HeadingProps,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading04({ forwardedRef, ...props }) {
  return <Heading {...props} size="4" ref={forwardedRef} />;
});

export const Heading05 = createLibraryComponent<
  HeadingProps,
  HTMLHeadingElement & HTMLSpanElement
>(function Heading05({ forwardedRef, ...props }) {
  return <Heading {...props} size="5" ref={forwardedRef} />;
});
