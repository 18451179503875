import { css, SerializedStyles } from '@emotion/react';
import { createLibraryComponent } from '@gds-web-ui/core';
import { gds } from '@gds-web-ui/design-tokens';
import { defineComponentStructure, VariantOf } from '@gds-web-ui/duxton-theme';
import { lazy, Suspense } from 'react';
import { ButtonContent, ButtonContentIconPosition } from './content';

const ButtonLoading = lazy(async () => import('./loading'));

function loadingGradient(color: string) {
  return `linear-gradient(90deg, ${color} -33.75%, rgba(191, 191, 191, 0) 117.82%)`;
}

export const buttonStructure = defineComponentStructure('mobile.button')
  .parts(
    'container',
    'base',
    'active',
    'skeleton',
    'content',
    'disabled',
    'variant'
  )
  .variants('primary', 'secondary', 'alert', 'ghost')
  .sizes('');

export const [defineButtonStyles, useButtonStyles] = buttonStructure.utils();

export const defaultButtonStyles = defineButtonStyles(
  ({
    spacing,
    backgroundColors,
    contentColors,
    brandColors,
    statesColors,
  }) => ({
    base: {
      container: {
        display: 'flex',
        height: '56px',
        cursor: 'pointer',
      },
      base: {
        flex: 1,
        display: 'flex',
        position: 'relative',
        padding: `${spacing.medium} ${spacing.large}`,
        border: 'none',
        borderRadius: '100vh',
        overflow: 'hidden',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        userSelect: 'none',
        cursor: 'pointer',
      },
      active: {
        ':active': {
          '::before': {
            content: '""',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            position: 'absolute',
          },
        },
      },
      disabled: {
        backgroundColor: backgroundColors.disabled,
        color: contentColors.disabled,
      },
      skeleton: {
        backgroundColor: 'transparent',
        '::before': {
          content: '""',
          background: loadingGradient(gds.gdsColorNeutral200),
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
          position: 'absolute',
        },
      },
      content: {
        zIndex: 10,
        flex: 1,
        display: 'flex',
      },
      variant: {},
    },
    variants: {
      primary: {
        variant: {
          backgroundColor: brandColors.primaryDefault,
          color: contentColors.defaultInverse,
        },
        active: {
          ':active': {
            '::before': {
              backgroundColor: brandColors.primaryBoldest,
            },
          },
        },
      },
      secondary: {
        variant: {
          backgroundColor: brandColors.secondarySoftest,
          color: brandColors.secondaryBoldest,
        },
        active: {
          ':active': {
            color: contentColors.defaultInverse,

            '::before': {
              backgroundColor: brandColors.secondaryDefault,
            },
          },
        },
      },
      alert: {
        variant: {
          backgroundColor: statesColors.alertDefault,
          color: contentColors.defaultInverse,
        },
        active: {
          ':active': {
            '::before': {
              backgroundColor: statesColors.alertBoldest,
            },
          },
        },
      },
      ghost: {
        variant: {
          background: 'transparent',
          color: contentColors.textLinkDefault,
        },
        active: {
          ':active': {
            '::before': {
              backgroundColor: backgroundColors.alt,
            },
          },
        },
        disabled: {
          background: 'transparent',
          color: contentColors.disabled,
        },
        skeleton: {
          backgroundColor: 'transparent',
          '::before': {
            content: '""',
            background: loadingGradient(gds.gdsColorNeutral200),
            borderRadius: '100vh',
            flex: 1,
          },
        },
      },
    },
  })
);

export type ButtonVariant = VariantOf<typeof buttonStructure>;

export interface ExportedButtonPropsBase {
  onPress?: () => void;
  disabled?: boolean;
  loading?: boolean;
  skeleton?: boolean;
}

export interface UnsafeButtonPropsInternal extends ExportedButtonPropsBase {
  cta?: string | undefined;
  label?: string | undefined;
  variant?: ButtonVariant | undefined;
  children?: JSX.Element | undefined;
  background?: SerializedStyles | undefined;
  backgroundColor?: string | undefined;
  icon?: string | JSX.Element | undefined;
  iconPosition?: ButtonContentIconPosition | undefined;
}

export interface SimpleButtonProps extends ExportedButtonPropsBase {
  cta: string;
  label?: string;
  variant?: ButtonVariant | undefined;
}

export interface FullyCustomisedButtonProps extends ExportedButtonPropsBase {
  children?: JSX.Element | undefined;
}

export interface CustomisedButtonProps extends ExportedButtonPropsBase {
  cta: string;
  background?: SerializedStyles | undefined;
}

export interface CustomisedButtonPropsWithoutEmotion
  extends ExportedButtonPropsBase {
  cta: string;
  backgroundColor?: string | undefined;
}

export interface ButtonPropsWithIcon extends ExportedButtonPropsBase {
  icon: string | JSX.Element;
  cta: string;
  iconPosition?: ButtonContentIconPosition | undefined;
}

export type ButtonProps =
  | SimpleButtonProps
  | FullyCustomisedButtonProps
  | CustomisedButtonProps
  | CustomisedButtonPropsWithoutEmotion
  | ButtonPropsWithIcon;

export const Button = createLibraryComponent<
  UnsafeButtonPropsInternal,
  ButtonProps,
  HTMLButtonElement
>(function Button({
  onPress,
  forwardedRef,
  UNSAFE_className,
  variant,
  disabled,
  skeleton,
  background,
  backgroundColor,
  cta,
  children,
  label,
  icon,
  iconPosition,
  loading,
}) {
  const hasContent = !skeleton;

  const { getStyles } = useButtonStyles(
    { variant: variant ?? 'primary', size: '' },
    defaultButtonStyles
  );

  const styles = [getStyles('base')];

  if (skeleton) {
    styles.push(getStyles('skeleton'));
  } else {
    if (!loading) {
      styles.push(getStyles('active'));
    }
    styles.push(getStyles('variant'));
  }

  // handle custom background color
  if (background) {
    styles.push(background);
  } else if (backgroundColor) {
    styles.push(css({ backgroundColor }));
  }

  const content = children || (
    <ButtonContent
      label={label}
      cta={cta}
      icon={icon}
      iconPosition={iconPosition}
    />
  );

  const inner = (
    <button
      ref={forwardedRef}
      css={styles}
      className={UNSAFE_className}
      onClick={loading ? undefined : onPress}
      disabled={disabled}
    >
      {hasContent && <div css={getStyles('content')}>{content}</div>}
    </button>
  );

  if (!skeleton && loading) {
    return (
      <div css={getStyles('container')}>
        <Suspense fallback={inner}>
          <ButtonLoading baseStyle={styles}>
            <div css={getStyles('content')}>{content}</div>
          </ButtonLoading>
        </Suspense>
      </div>
    );
  }

  return <div css={getStyles('container')}>{inner}</div>;
});
