const value = Symbol('lazyval');

/**
 * Holds a value that's computed once when you first call it.
 */
export class Lazy<T> {
  private _value: T | typeof value = value;

  constructor(private force: () => T) {}

  get value(): T {
    if (this._value === value) {
      this._value = this.force();
    }
    return this._value;
  }
}

/**
 * Create a value that's computed once when you first call it.
 *
 * For example:
 * ```
 * let p = 0
 * const result = lazy(() => 1 + (++p))
 * console.log(result.value) // 2
 * console.log(result.value) // 2
 * console.log(p) // 1
 * ```
 *
 * @param force the value to evaluate to
 */
export function lazy<T>(force: () => T): Lazy<T> {
  return new Lazy(force);
}
