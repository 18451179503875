import React, { useMemo } from 'react';

interface UseIdOptions {
  prefix?: string | undefined;
  idProp?: string | undefined;
}

const isReactLessThan18 =
  React.version.startsWith('16.') || React.version.startsWith('17.');

let incrementalId = 0;

export const useId = isReactLessThan18
  ? ({ prefix = 'gds', idProp }: UseIdOptions = {}) => {
      return useMemo(
        () => idProp || [prefix, ++incrementalId].filter(Boolean).join('-'),
        [idProp, prefix]
      );
    }
  : ({ prefix = 'gds', idProp }: UseIdOptions = {}) => {
      const generatedId = React.useId();
      return useMemo(
        () => idProp || [prefix, generatedId].filter(Boolean).join('-'),
        [idProp, prefix, generatedId]
      );
    };
