import { CSSInterpolation } from '@emotion/serialize';
import { Option, ShrinkTextToFit } from '@gds-web-ui/core';
import { ForwardedRef, forwardRef, HTMLAttributes, Ref } from 'react';
import { Interpolation, Theme as EmotionTheme } from '@emotion/react';

type InternalComponent = React.ComponentType<
  HTMLAttributes<
    HTMLSpanElement & HTMLParagraphElement & HTMLHeadingElement
  > & {
    ref: Ref<HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement>;
  }
>;

export interface TextInternalProps
  extends Omit<
    HTMLAttributes<HTMLSpanElement & HTMLParagraphElement & HTMLHeadingElement>,
    'css' | 'style'
  > {
  component:
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | React.ComponentType<
        HTMLSpanElement & HTMLParagraphElement & HTMLHeadingElement
      >;
  span?: boolean;
  shrinkToFit?: boolean;
  children?: React.ReactNode;
  style?: Option<CSSInterpolation | Interpolation<EmotionTheme> | null>;
}

export const TextInternal = forwardRef(
  (
    {
      component = 'span',
      span,
      shrinkToFit,
      children,
      style,
      ...props
    }: TextInternalProps,
    ref: ForwardedRef<
      HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement
    >
  ) => {
    const Component: InternalComponent = (shrinkToFit
      ? ShrinkTextToFit
      : span
      ? 'span'
      : component) as unknown as InternalComponent;

    if (Component === ShrinkTextToFit) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props as any).style = style;
    }

    return (
      <Component {...props} css={style} ref={ref}>
        {children}
      </Component>
    );
  }
);
