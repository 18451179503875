import {
  createThemeContext,
  makeDefineComponentStructure,
} from '@gds-web-ui/styling';
import { defaultTheme } from './defaultTheme';
import { DuxtonTheme } from './types';

export const [ThemeProvider, useTheme, ThemeContext] =
  createThemeContext<DuxtonTheme>(defaultTheme, {
    name: 'DuxtonThemeContext',
    providerName: '<ThemeProvider />',
    hookName: 'useTheme()',
  });

export const { defineComponentStructure, defineStyles, serialize } =
  makeDefineComponentStructure(ThemeContext);
