import React from "react";
import "./App.css";
import "@splidejs/react-splide/css";
import { useWindowSize } from "react-use";
import { AppBar, Button, ThemeProvider } from "@gds-web-ui/duxton";
import { parser } from "./parser";
import Vibrant from "node-vibrant";
import { getColorsFromSwatches } from "./utils";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function App() {
  const [text, setText] = React.useState("Taste Thailand in every bite");
  const [textColor, setTextColor] = React.useState("#ffffff");
  const [textBgColor, setTextBgColor] = React.useState("#90B699");
  const [textSize, setTextSize] = React.useState(20);
  const [textBgWidth, setTextBgWidth] = React.useState(35);
  const [suggestions, setSuggestions] = React.useState<{
    text_suggestions: string[];
    color_suggestions: {
      text_color: string;
      background_color: string;
    }[];
  }>({ text_suggestions: [], color_suggestions: [] });
  const [index, setIndex] = React.useState(1);
  const [swatches, setSwatches] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTextIndex, setSelectedTextIndex] = React.useState(0);
  const [selectedColorIndex, setSelectedColorIndex] = React.useState(0);

  const { width } = useWindowSize();
  const cardWidth = width - 32 * 2;

  const handleImageChange = () => {
    let newIndex = (index % 7) + 1;
    setIndex(newIndex);

    Vibrant.from(`thumbnails/thumbnail${newIndex}.jpg`)
      .maxColorCount(128)
      .getSwatches()
      .then((swatches) => {
        setSwatches(getColorsFromSwatches(swatches));
        console.log(getColorsFromSwatches(swatches));
      });
  };

  return (
    <ThemeProvider>
      <div>
        <AppBar content="Maximizing Merchant Marketing" navigationIcon="back" />

        <div
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <div
            style={{
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
              borderRadius: 16,
              padding: 16,
            }}
          >
            <div
              style={{
                marginBottom: 16,
                fontWeight: 600,
                // fontFamily: "Sanomat Grab App",
              }}
            >
              Order Now
            </div>
            <div
              style={{
                width: cardWidth,
                height: 200,
                position: "relative",
                borderRadius: 16,
                // margin: 16,
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: (1 - textBgWidth / 100) * cardWidth,
                  height: 200,
                  borderTopRightRadius: 16,
                  borderBottomRightRadius: 16,
                }}
                src={`thumbnails/thumbnail${index}.jpg`}
                alt="thumbnail"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: (textBgWidth / 100) * cardWidth,
                  backgroundColor: textBgColor,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 16,
                  borderTopLeftRadius: 16,
                  borderBottomLeftRadius: 16,
                }}
              >
                <div
                  style={{
                    fontFamily: "Grab Community",
                    color: textColor,
                    fontSize: textSize,
                  }}
                >
                  {text}
                </div>
              </div>
            </div>
            <div>
              <div style={{ fontWeight: 500, marginTop: 8 }}>
                Your next meal is a couple of clicks away
              </div>
              <div style={{ marginTop: 8 }}>Ad • Wa Restaurant</div>
            </div>
          </div>
        </div>

        {/* <div style={{ display: "flex", flexDirection: "row" }}>
        {swatches.map((swatch) => (
          <div
            style={{
              width: 50,
              height: 50,
              backgroundColor: swatch,
              margin: 8,
            }}
          />
        ))}
      </div> */}

        <div style={{ padding: 16 }}>
          <div>Title</div>
          <input
            type="text"
            id="textInput"
            placeholder="Enter your text"
            value={text}
            style={{
              width: width - 48,
              height: 32,
              borderRadius: 8,
              border: "1px solid #005339",
              paddingLeft: 8,
              paddingRight: 8,
            }}
            onChange={(e) => setText(e.target.value)}
          />
          <div style={{ height: 8 }} />

          <div>Text Size</div>
          <input
            type="range"
            id="font-size-slider"
            min="16"
            max="36"
            step="2"
            value={textSize}
            style={{ accentColor: "#005339", width: "100%" }}
            onChange={(e) => setTextSize(parseInt(e.target.value))}
          />
          <div style={{ height: 8 }} />

          <div>Text background width</div>
          <input
            type="range"
            id="font-bg-width-slider"
            min={25}
            max="100"
            step="1"
            value={textBgWidth}
            style={{ accentColor: "#005339", width: "100%" }}
            onChange={(e) => setTextBgWidth(parseInt(e.target.value))}
          />
          <div style={{ height: 16 }} />

          {suggestions.text_suggestions.length !== 0 && (
            <div>Suggested Ad Text</div>
          )}
          <div style={{ height: 8 }} />

          <Carousel
            showIndicators={false}
            showArrows={true}
            showStatus={false}
            onChange={(newIndex) => {
              // console.log(newIndex);
              setSelectedTextIndex(newIndex);
              setText(suggestions.text_suggestions[newIndex]);
            }}
            showThumbs={false}
            selectedItem={selectedTextIndex}
          >
            {suggestions.text_suggestions.map((text, i) => (
              <div
                onClick={() => {
                  setText(text);
                }}
                style={{
                  backgroundColor: "#575757",
                  color: "#ffffff",
                  paddingBottom: 16,
                  paddingTop: 16,
                  textAlign: "center",
                  borderRadius: 8,
                  fontSize: 14,
                  fontFamily: "Grab Community Solid",
                }}
              >
                "{text}"
              </div>
            ))}
          </Carousel>

          {suggestions.color_suggestions.length !== 0 && (
            <div>Suggested Colors</div>
          )}
          <div style={{ height: 8 }} />

          <Carousel
            showIndicators={false}
            showArrows={true}
            showStatus={false}
            onChange={(newIndex) => {
              setSelectedColorIndex(newIndex);
              setTextColor(suggestions.color_suggestions[newIndex].text_color);
              setTextBgColor(
                suggestions.color_suggestions[newIndex].background_color
              );
            }}
            showThumbs={false}
            selectedItem={selectedColorIndex}
          >
            {suggestions.color_suggestions.map((suggestion) => (
              <div
                key={`${suggestion.text_color}-${suggestion.background_color}`}
                style={{
                  width: "100%",
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: suggestion.background_color,
                  color: suggestion.text_color,
                  fontSize: 22,
                  cursor: "pointer",
                  borderRadius: 8,
                  fontFamily: "Grab Community",
                }}
                onClick={() => {
                  setTextColor(suggestion.text_color);
                  setTextBgColor(suggestion.background_color);
                }}
              >
                Grabathon X
              </div>
            ))}
          </Carousel>
          <div style={{ height: 16 }} />

          <Button
            cta="Change Photo"
            variant="secondary"
            onPress={handleImageChange}
          />
          <div style={{ height: 8 }} />

          <Button
            loading={isLoading}
            cta="Ask AI for Suggestions"
            variant="primary"
            onPress={() => {
              setIsLoading(true);
              fetch(`https://133d-18-136-237-210.ngrok-free.app/suggest`, {
                method: "POST",
                body: JSON.stringify({
                  text,
                  format_instructions: parser.getFormatInstructions(),
                  swatches,
                }),
              }).then(async (res) => {
                const response_text = await res.text();
                const response = await parser.parse(response_text);
                console.log(response);
                setSuggestions(response);
                setSelectedColorIndex(0);
                setSelectedTextIndex(0);
                setText(response.text_suggestions[0]);
                setTextColor(response.color_suggestions[0].text_color);
                setTextBgColor(response.color_suggestions[0].background_color);
                setIsLoading(false);
              });
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
