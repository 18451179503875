import { defineComponentStructure } from '@gds-web-ui/duxton-theme';
import { Heading03, Metadata01 } from '@gds-web-ui/duxton-typography';
import { NavigationIcon } from './navigation-icon';

export interface AppBarTitlePOIProps {
  subheading: string;
  heading: string;
  onPress?: () => void;
}

export const appBarPOIStructure = defineComponentStructure('mobile.appbar.poi')
  .parts('container', 'heading', 'subheading', 'dropdownContainer', 'dropdown')
  .variants('default', 'pressable')
  .sizes('default');

export const [defineAppBarPOIStyles, useAppBarPOIStyles] =
  appBarPOIStructure.utils();

export const defaultAppBarPOIStyles = defineAppBarPOIStyles(
  ({ contentColors, typography, spacing, backgroundColors }) => ({
    base: {
      container: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        color: contentColors.default,
      },
      heading: [typography.heading03Bolder, { color: contentColors.default }],
      subheading: typography.metadata01Regular,
      dropdownContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '7px',
        alignItems: 'center',
      },
      dropdown: {
        width: spacing.medium,
        height: spacing.medium,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    variants: {
      pressable: {
        container: {
          ':active': {
            backgroundColor: backgroundColors.alt,
          },
        },
      },
    },
  })
);

export const AppBarPOI = ({
  heading,
  subheading,
  onPress,
}: AppBarTitlePOIProps) => {
  const { getStyles } = useAppBarPOIStyles(
    { variant: onPress ? 'pressable' : 'default', size: 'default' },
    defaultAppBarPOIStyles
  );

  return (
    <div
      css={getStyles('container')}
      onClick={onPress}
      role="heading"
      aria-level={1}
    >
      <Metadata01>{subheading}</Metadata01>
      <div css={getStyles('dropdownContainer')}>
        <Heading03 span ignoreAriaRoleAndLevel>
          {heading}
        </Heading03>
        <div css={getStyles('dropdown')}>
          <NavigationIcon type="dropdown" />
        </div>
      </div>
    </div>
  );
};
