import { duxtonOvo } from '@gds-web-ui/design-tokens';
import { capitalize, extendTheme } from './builder';
import { defaultTheme } from './defaultTheme';

export const ovoTheme = /* @__PURE__ */ extendTheme('duxtonOvo', defaultTheme, {
  typography: (entry) => duxtonOvo[`duxtonOvoTextStyle${capitalize(entry)}`],
  brandColors: (entry) => duxtonOvo[`duxtonOvoColorBrand${capitalize(entry)}`],
  backgroundColors: (entry) =>
    duxtonOvo[`duxtonOvoColorBackground${capitalize(entry)}`],
  contentColors: (entry) =>
    duxtonOvo[`duxtonOvoColorContent${capitalize(entry)}`],
  statesColors: (entry) =>
    duxtonOvo[`duxtonOvoColorStatus${capitalize(entry)}`],
  outlineColors: (entry) =>
    duxtonOvo[`duxtonOvoColorOutline${capitalize(entry)}`],
});
