import { DuxtonTheme } from './types';

type Specification = {
  [k in Exclude<keyof DuxtonTheme, 'textColors' | 'name'>]:
    | (<Entry extends keyof DuxtonTheme[k]>(
        entry: Entry
      ) => DuxtonTheme[k][Entry])
    | DuxtonTheme[k];
};

type PartialSpecification = {
  [k in Exclude<keyof DuxtonTheme, 'textColors' | 'name'>]?:
    | (<Entry extends keyof DuxtonTheme[k]>(
        entry: Entry
      ) => DuxtonTheme[k][Entry] | undefined)
    | DuxtonTheme[k];
};

function cache<T>(
  store: Partial<T>,
  handler: (<K extends keyof T>(key: K) => T[K]) | T
) {
  switch (typeof handler) {
    case 'function':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Proxy<any>(store, {
        get(target, p) {
          if (target[p] || typeof p !== 'string') {
            return target[p];
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result = (handler as any)(p);
          target[p] = result;
          return result;
        },
      });
    default:
      return handler;
  }
}

export function capitalize<T extends string>(t: T): Capitalize<T> {
  return (t.charAt(0).toUpperCase() + t.slice(1)) as Capitalize<T>;
}

export function buildTheme(name: string, specs: Specification): DuxtonTheme {
  function createInternalProxy<T extends Exclude<keyof DuxtonTheme, 'name'>>(
    key: T
  ): DuxtonTheme[T] {
    if (key === 'textColors') {
      console.warn('textColors is deprecated. Use contentColors instead.');
      return cache({}, specs.contentColors);
    } else {
      return cache(
        {},
        specs[key as Exclude<keyof DuxtonTheme, 'name' | 'textColors'>]
      );
    }
  }

  return cache({ name }, createInternalProxy);
}

export function extendTheme(
  name: string,
  base: DuxtonTheme,
  specs: PartialSpecification
) {
  function createOverrideProxy<T extends keyof PartialSpecification>(
    key: T
  ): DuxtonTheme[T] {
    const spec = specs[key];
    if (spec) {
      switch (typeof spec) {
        case 'function': {
          const override = <K extends keyof DuxtonTheme[T]>(
            entry: K
          ): DuxtonTheme[T][K] => {
            const result = spec(entry);
            if (result) {
              return result;
            }
            return base[key][entry];
          };
          return cache({}, override);
        }
        default: {
          return cache({}, spec as DuxtonTheme[T]);
        }
      }
    } else {
      return base[key];
    }
  }

  const override = cache({}, createOverrideProxy);

  return new Proxy(base, {
    get(base, key, receiver) {
      console.log(key);
      const param = key as keyof PartialSpecification;
      if (specs[param] || typeof param !== 'string') {
        return override[param];
      } else {
        return base[param];
      }
    },
  });
}
