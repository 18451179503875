import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';
import { nextFrame } from '@gds-web-ui/utils-async';
import { useForkRef } from '@gds-web-ui/utils-fork-ref';
import { measure } from '@gds-web-ui/utils-height';
import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useRef,
} from 'react';

export interface ShrinkTextToFitProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, 'style' | 'css'> {
  style?: CSSInterpolation;
  css?: CSSInterpolation;
  children: string | JSX.Element | undefined;
}

const styles = {
  container: css({
    display: 'block',
    overflow: 'hidden',
  }),
  span: css({
    whiteSpace: 'nowrap',
  }),
};

const ShrinkTextToFitImpl = (
  { style, css, children, ...spanProps }: ShrinkTextToFitProps,
  ref: ForwardedRef<HTMLSpanElement>
) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const spanRefMerged = useForkRef(ref, spanRef);

  useEffect(() => {
    if (spanRef.current && containerRef.current) {
      const span = spanRef.current;
      const container = containerRef.current;

      const effect = async () => {
        span.style.removeProperty('font-size');
        await measure.offsetWidth(span, 15);

        if (span.offsetWidth > container.offsetWidth) {
          let fontSize = +getComputedStyle(span)
            .getPropertyValue('font-size')
            .replace('px', '');

          for (
            let adjust = 5;
            adjust > 0.5 && span.offsetWidth > container.offsetWidth;
            adjust /= 2
          ) {
            fontSize = (fontSize - adjust) | 0;
            span.style.fontSize = `${fontSize}px`;
            await nextFrame();
            if (span.offsetWidth < container.offsetWidth) {
              fontSize = (fontSize + adjust / 2) | 0;
              span.style.fontSize = `${fontSize}px`;
              await nextFrame();
            }
          }
        }
      };
      effect();
    }
  }, [children]);

  return (
    <div css={styles.container} ref={containerRef}>
      <span {...spanProps} css={[styles.span, style, css]} ref={spanRefMerged}>
        {children}
      </span>
    </div>
  );
};

/**
 * ShrinkTextToFit resizes the font size down so that the content is visible
 * for the entire screen. This is mostly used for Mobile Web where we need
 * to display 1 line that may not fit the width of the screen.
 *
 * Note that at the current point of time, this component does not listen
 * to resize observers, and assumes that the viewport size is constant. This
 * is a reasonable assumption for PAX, since we don't have orientation changes
 * and very rarely do we deal with dimension changes.
 */
export const ShrinkTextToFit = forwardRef(ShrinkTextToFitImpl);
